import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import * as PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { getCValues } from '../apis/api';
import withQueryParameters from '../hocs/withQueryParameters';
import PositionContext from './common/PositionContext';

const useStyles = makeStyles((theme) => ({
  jobRoot: (props) => ({
    display: 'inline-block',
    width: '100%',
    paddingBottom: theme.spacing(1),
    borderBottom: props.dialogDisplay
      ? 0
      : `1px solid ${theme.palette.grey[300]}`,
  }),
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    color: theme.palette.text.secondary,
  },
  companyLogoHolder: {
    float: 'left',
    height: 50,
    width: 50,
    borderRadius: '50%',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  companyLogo: {
    height: 50,
    width: 50,
    maxHeight: 50,
    maxWidth: 50,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  jobTitle: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#4285F4',
  },
  jobDetails: (props) => ({
    [theme.breakpoints.up('sm')]: {
      display: props.dialogDisplay ? 'flex' : 'block',
      justifyContent: 'space-between',
    },
    display: 'block',
    overflow: 'hidden',
    textAlign: 'left',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  }),
  jobCaption: (props) => ({
    [theme.breakpoints.up('sm')]: {
      width: props.dialogDisplay ? '75%' : 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    display: 'inline-block',
    paddingBottom: theme.spacing(1),
  }),
  icons: {
    fontSize: '0.9em',
  },
  applyNowButtonHolder: {
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      float: 'right',
    },
  },
  applyNowButton: {
    textTransform: 'none',
  },
  spacer: {
    display: 'inline-block',
    width: 8,
  },
}));

const JobContainer = ({ job, handleJob, dataGaType, dialogDisplay }) => {
  const classes = useStyles({ dialogDisplay });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const context = useContext(PositionContext);
  const { title, url, company, city, logo_url, id } = job;
  const cParams = new URLSearchParams(getCValues()).toString();
  const { email } = context.state;
  const newUrl = `${url}${email ? `&email=${email}` : ''}&${cParams}`;

  /* eslint-disable react/jsx-no-target-blank */
  return (
    <div className={classes.jobRoot}>
      <Link
        href={newUrl}
        target="_blank"
        className={classes.link}
        onClick={() => handleJob(id)}
        data-ga-type={dataGaType}
        data-ga-content={id}
      >
        <div className={classes.companyLogoHolder}>
          <div
            className={classes.companyLogo}
            title={`${company} Company Logo`}
            style={{ backgroundImage: `url(${logo_url})` }}
          />
        </div>
        <div className={classes.jobDetails}>
          <div className={classes.jobCaption}>
            <Typography
              variant="body1"
              className={classes.jobTitle}
            >{title}</Typography>
            <Typography variant="caption">
              <LocationCityIcon className={classes.icons} /> {company}
              {matches && !dialogDisplay
                ? <span className={classes.spacer}></span> : <br />}
              <LocationOnIcon className={classes.icons} /> {city}
            </Typography>
          </div>
          <div className={classes.applyNowButtonHolder}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              fullWidth
              className={classes.applyNowButton}
            >
              View
            </Button>
          </div>
        </div>
      </Link>
    </div>
  );
};

JobContainer.propTypes = {
  job: PropTypes.object,
  handleJob: PropTypes.func.isRequired,
  dataGaType: PropTypes.string,
  dialogDisplay: PropTypes.bool,
};

JobContainer.defaultProps = {
  job: {},
  dataGaType: 'Job_Click',
  dialogDisplay: false,
};

export default withQueryParameters(JobContainer);
