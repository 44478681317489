import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post4 = () => {
  const classes = useStyles();
  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/resume-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/resume-tips`}
        >
          Resume Tips
        </Link>
      </Typography>
    </div>
    <article aria-label="7 Things You Should Never Include on a Resume">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 466 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/05/7neversresume.png"
          alt="resume help"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/nkhaykis`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/nkhaykis`}
            rel="author"
          >
            Natalya Khaykis
          </Link>
          &nbsp;/&nbsp;
          <time>January 25, 2018</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          7 Things You Should Never Include on a Resume
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          Let’s face it, very few people really enjoy writing a resume. A quick
          web search on “how to write a resume” brings up tons of articles that
          often contradict each other when it comes to “best practices.” What do
          you include on a resume? What’s outdated and better left off? Well,
          we’re going to make it much easier for you. Here are seven things that
          should not be included on a resume today.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          1 - Your Full Street Address
        </Typography>
        <Typography variant="body1" gutterBottom>
          You should never include your full street address on a resume for a
          number of reasons. For one, some companies don’t even open a resume
          with a full address due to privacy reasons. Privacy laws are complex
          and without getting into all the details, including too much personal
          information on your resume could bear consequences a company just
          doesn’t want to deal with.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You also don’t want a hiring manager to reject your application
          because you have a longer commute than other candidates. They may
          think that you’ll be late to work more often due to inclement weather
          or traffic.
        </Typography>
        <Typography variant="body1" gutterBottom>
          A full address just isn’t necessary. It used to be but the bulk of
          communication is now easily handled through email. You should only
          include your city and state.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          2 - An Objective Statement
        </Typography>
        <Typography variant="body1" gutterBottom>
          You should never include an objective statement on a resume as it’s
          outdated and ineffective. An objective statement tells the employer
          what you want and honestly, they could care less.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here is an example of an objective statement:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <i>
            Marketing professional seeking an opportunity to use my social media
            and content marketing skills to increase traffic and awareness.
          </i>
        </Typography>
        <Typography variant="body1" gutterBottom>
          The hiring manager is well aware that you’re looking to obtain a
          position. This statement does nothing to convince the hiring manager
          that you’re a good fit for the position.
        </Typography>
        <Typography variant="body1" gutterBottom>
          So, what should you replace it with? A well-written summary.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use a <a
          href="https://www.zipjob.com/blog/writing-a-good-resume-summary/"
          target="_blank" rel="noopener noreferrer">
          resume summary</a> and tell the employer a bit about your background,
          qualification, skills and measurable achievements throughout your
          career. This will show them that you’ve got what it takes and will
          help you stand out from the crowd.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          3 - Buzzwords
        </Typography>
        <Typography variant="body1" gutterBottom>
          Good word choice is really important for a resume. Many job seekers
          use “buzzwords” that annoy most hiring managers. They’re usually vague
          soft skills that are overused and subjective.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here are the top <a
          href="http://www.careerbuilder.com/share/aboutus/pressreleasesdetail.aspx?id=pr809&amp;sd=3/13/2014&amp;ed=03/13/2014"
          target="_blank" rel="nofollow noopener noreferrer">
          10 words hiring managers hate
        </a>&nbsp;to see on a resume:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Best of
            breed</Typography></li>
          <li><Typography variant="body1" gutterBottom>Go-getter</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Think outside of the
            box</Typography></li>
          <li><Typography variant="body1" gutterBottom>Synergy</Typography></li>
          <li><Typography variant="body1" gutterBottom>Go-to person</Typography>
          </li>
          <li><Typography variant="body1"
                          gutterBottom>Results-driven</Typography></li>
          <li><Typography variant="body1" gutterBottom>Team player</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Hard worker</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Strategic
            thinker</Typography></li>
          <li><Typography variant="body1"
                          gutterBottom>Detail-oriented</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          You should instead use strong action verbs and indirectly show your
          soft skills through your achievements.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          4 - Images, Designs and Fancy Fonts
        </Typography>
        <Typography variant="body1" gutterBottom>
          Some job seekers turn their resume into an art project and that’s
          likely to be rejected by most employers. You should never include
          images, designs or fancy fonts on a resume for a few reasons.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Images, designs and charts have difficulty getting through an ATS
          (Applicant Tracking System). An ATS is software used by most employers
          to automatically screen resumes and when they encounter resumes they
          can’t read, they’re automatically rejected. The same goes for fancy
          fonts which are unprofessional and potentially impossible for an ATS
          to read. Here is a list of the&nbsp;
          <a href="https://www.zipjob.com/blog/best-font-for-resume/"
             target="_blank" rel="noopener noreferrer">
            best fonts to use on a resume
          </a>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The majority of hiring managers also prefer a standard, clean and
          professional resume.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          5 - References
        </Typography>
        <Typography variant="body1" gutterBottom>
          Including references or the common phrase “references available on
          request” is also outdated and unnecessary. If a hiring manager needs
          your references, they will ask for them. You should, however, have a
          professional references page ready when a hiring manager does ask for
          them.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          6 - Irrelevant Experience
        </Typography>
        <Typography variant="body1" gutterBottom>
          Many job seekers make the mistake of including tons of irrelevant
          experience on their resume. Irrelevant information will hurt your
          chances of landing an interview as the point of a resume is to show
          the employer that you have the qualifications and experience to get
          the job done.
        </Typography>
        <Typography variant="body1" gutterBottom>
          For example, if you’re targeting a marketing job, don’t include 2
          pages of experience as a cashier. If you don’t have relevant
          experience, lead with a strong summary and mention the achievements
          that most closely relate to the position you’re targeting.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          7 - Typos
        </Typography>
        <Typography variant="body1" gutterBottom>
          Numerous surveys have revealed that a resume with even a single
          grammatical or spelling error will be rejected by a hiring
          manager.&nbsp;
          <a href="https://advice.j2c.com/create-flawless-resume/">
            Your resume is the first impression
          </a>&nbsp;you have on a hiring manager and you need to ensure your
          resume is
          error free. Have someone else proofread your resume before sending it
          out.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Times have changed and some of what was considered “best practices” on
          a resume are now outdated and ineffective. Your resume is the most
          important document for your job search so put in the time and effort
          to write one that’s effective. Good luck with your job search!
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/resume-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/resume-tips`}
            rel="category tag"
          >
            Resume Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post4;
