import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import withQueryParameters from '../../hocs/withQueryParameters';
import { buildProfileUrl } from '../../utils/buildUrl';
import PositionContext from '../common/PositionContext';
import JobsDisplay from '../JobsDisplay';
import ResultsPage from '../page_layouts/ResultsPage';

const ResultsRoute = ({ bypass, ...rest }) => {
  const context = useContext(PositionContext);
  const { submitPosition } = context.state;

  let component = <Redirect to={buildProfileUrl()} />;
  if (submitPosition || bypass) {
    component = (
      <ResultsPage>
        <JobsDisplay {...rest} />
      </ResultsPage>
    );
  }

  return (
    <Route {...rest} render={() => component} />
  );
};

export default withQueryParameters(ResultsRoute);
