import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from 'prop-types';
import React, { useContext } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { zipCodeToCityState } from '../../utils/locationUtils';
import Disclosure from '../common/Disclosure';
import themes from '../../assets/themes';

import PositionContext from '../common/PositionContext';
import CompaniesWidget from '../widgets/companies/CompaniesWidget';
import Profile2Form from './Profile2Form';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingRight: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  dialogContent: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(12),
    },
  },
  closeButtonHolder: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  messageHolder: {
    paddingBottom: theme.spacing(2),
  },
}));

const Transition = React.forwardRef((props, ref) =>
  <Slide direction="up" ref={ref} {...props} />);

const Profile2Dialog = ({ openFlagName, title }) => {
  const classes = useStyles();
  const context = useContext(PositionContext);
  const { [openFlagName]: open, zipCode } = context.state;

  const handleClose = () => {
    context.updateValue(openFlagName, false);
  };

  const companiesWidgetProps = {
    ad_props: {
      search_type: 'CompaniesByLocation',
      display_type: 'Carousel',
    },
    title: 'Great Companies Like These Are Hiring In Your Area',
    loc: zipCodeToCityState(zipCode),
    limit: 10,
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      maxWidth="sm"
      onClose={() => handleClose()}
    >
      <div className={classes.closeButtonHolder}>
        <IconButton
          color="inherit"
          onClick={() => handleClose()}
          aria-label="close"
          data-ga-type="Close_Dialog"
          data-ga-content={title}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle id="dialog-title" className={classes.header}>
        {themes.main.emailHeader}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {themes.main.emailCompanyWidget && (
          <>
            <CompaniesWidget {...companiesWidgetProps} />
            <div className={classes.messageHolder}>
              <Typography variant="body1">
                Sign Up For Job Alerts to Receive the Newest Job Offers Before
                Anyone Else
              </Typography>
            </div>
          </>
        )}
        <Profile2Form submitCallback={handleClose} />
        <br />
        <Button onClick={() => handleClose()} size="small">No Thanks</Button>
        <Disclosure />
      </DialogContent>
    </Dialog>
  );
};

Profile2Dialog.propTypes = {
  openFlagName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Profile2Dialog;
