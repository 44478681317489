import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const AdSenseAd = (props) => {
  const {pathname, search} = props.location;

  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, [pathname, search]);

  return (
    <div key={`${pathname}${search}`}>
      <ins
        className={`${props.className} adsbygoogle`}
        style={props.style}
        data-ad-client={props.client}
        data-ad-slot={props.slot}
        data-ad-layout={props.layout}
        data-ad-layout-key={props.layoutKey}
        data-ad-format={props.format}
        data-full-width-responsive={props.responsive}
      />
    </div>
  );
};

AdSenseAd.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  layoutKey: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string,
};

AdSenseAd.defaultProps = {
  className: '',
  style: { display: 'block' },
  format: 'auto',
  layout: '',
  layoutKey: '',
  responsive: 'false',
};

export default withRouter(AdSenseAd);
