import React from 'react';
import { Redirect } from 'react-router-dom';
import authors from '../blog/Authors';

import posts from '../blog/Posts';
import Author from '../common/Author';
import BlogPageLayout from './BlogPageLayout';

const BlogPage = ({ match }) => {
  const postSlug = match.params['post'];
  let component = <Redirect to='/404' />;

  if (postSlug in posts) {
    const post = posts[postSlug];
    const BlogPost = post.post;
    const authorSlug = post.author;
    const author = authors[authorSlug];
    component = (
      <BlogPageLayout>
        <BlogPost />
        <Author
          name={author.name}
          image={author.image}
          desc={author.desc}
          slug={authorSlug}
        />
      </BlogPageLayout>
    );
  }
  return component;
};

export default BlogPage;
