import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import withQueryParameters from '../../hocs/withQueryParameters';
import { buildResultsUrl } from '../../utils/buildUrl';
import Footer from '../common/Footer';
import PositionContext from '../common/PositionContext';
import CaptureFunnelPage from '../page_layouts/CaptureFunnelPage';
import PositionPickerPage from '../page_layouts/PositionPickerPage';
import RegPathPage from '../page_layouts/RegPathPage';
import ZipForm from '../ZipCodeForm';

const ProfileRoute = ({ q, l, bypass, unsubscribeId, geo, ...rest }) => {
  const context = useContext(PositionContext);

  const {
    zipCode,
    zipSubmit,
    regPath,
  } = context.state;

  let component = null;
  if (!bypass) {
    if (!zipCode) {
      component = (
        <CaptureFunnelPage>
          <ZipForm />
          <Footer />
        </CaptureFunnelPage>
      );
    } else if (zipSubmit && regPath) {
      component = <RegPathPage />;
    } else {
      component = <PositionPickerPage />;
    }
  } else {
    component = <Redirect to={buildResultsUrl(q, l)} />;
  }

  return (
    <Route {...rest} render={() => component} />
  );
};

export default withQueryParameters(ProfileRoute);
