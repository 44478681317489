import { Typography } from '@material-ui/core';
import React from 'react';

import api from '../../apis/api';
import { siteName } from '../../assets/themes';
import withQueryParameters from '../../hocs/withQueryParameters';
import BasicPageLayout from './BasicPageLayout';

const UnsubscribePage = ({ _data_ }) => {
  const decoded_data = JSON.parse(atob(_data_));

  // TODO: Make this a conditional render based on the response.
  api.unsubscribeContact(decoded_data.email).then((response) => {
    console.log(response);
  }).catch((error) => console.log(error));

  return (
    <BasicPageLayout>
      <Typography variant="body1">
        You have been successfully unsubscribed from {siteName}.com.
      </Typography>
    </BasicPageLayout>
  );
};

export default withQueryParameters(UnsubscribePage);
