import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Cookies from 'js-cookie';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import api from '../apis/api';
import environment from '../configs/environment';
import withQueryParameters from '../hocs/withQueryParameters';
import { buildResultsUrl } from '../utils/buildUrl';
import AdSenseAd from './common/AdSenseAd';
import PopAd from './common/PopAd';
import PositionContext from './common/PositionContext';

const useStyles = makeStyles((theme) => ({
  recommendations: {
    display: 'inline-block',
    borderTop: '1px solid black',
    minHeight: 115,
    width: '100%',
    paddingTop: theme.spacing(1),
    minLength: 1000,
  },
  recommendationHolder: {
    color: theme.palette.info.dark,
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    paddingRight: theme.spacing(1),
  },
  link: {
    textTransform: 'lowercase',
    textDecoration: 'none',
  },
  loading: {
    textAlign: 'center',
  },
  titleHolder: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
}));

const Recommendations = ({ history, q, showAd }) => {
  const context = useContext(PositionContext);
  const classes = useStyles();
  const { position, recommendations } = context.state;

  const handleClick = (recommendation) => {
    let { zipCode } = context.state;
    Cookies.set('position', recommendation);
    context.updateValue('position', recommendation);
    history.push(
      buildResultsUrl(recommendation, zipCode, 0, { t1: 'related' }),
    );
    const scroll = document.getElementById('JobHeader');
    scroll.scrollIntoView({ block: 'end', inline: 'nearest' });
  };

  useEffect(
    () => {
      getRecommendations(q ? q : position);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [q, position],
  );

  const getRecommendations = (recommendation) => {
    api.getRecommended(
      recommendation ? recommendation.toLowerCase() : '+',
      4,
    ).then((response) => {
      context.updateValue('recommendations', response);
    }).catch(() => {
      api.getRecommended('+', 4).then((response) => {
        context.updateValue('recommendations', response);
      });
    });
  };

  let component = (
    <>
      <Typography variant="h6" className={classes.titleHolder}>
        People Also Searched For
      </Typography>
      <div>
        {recommendations.length > 0
          ? recommendations.map((recommendation) => (
            <div key={recommendation}>
              <Button
                className={classes.link}
                title={recommendation}
                onClick={() => handleClick(recommendation)}
                data-ga-type="Related_Query_Click"
                data-ga-content={recommendation}
              >
                <div className={classes.recommendationHolder}>
                  <SearchIcon className={classes.search} />
                  <Typography variant="body1">
                    {recommendation}
                  </Typography>
                </div>
              </Button>
              <hr />
            </div>
          ))
          : <CircularProgress className={classes.loading} />}
      </div>
    </>
  );

  const adSpec = environment.job_list_ads[showAd || 0]
  if(adSpec){
    switch (adSpec.type) {
      case 'adSense':
        component = (
          <div className={classes.recommendations}>
            <AdSenseAd {...adSpec} />
          </div>
        );
        break;
      case 'pop':
        component = (
          <div className={classes.recommendations}>
            <PopAd {...adSpec} />
          </div>
        );
        break;
      case 'none':
      default:
        component = (<></>)
        break;
    }
  }

  return component;
};

export default withQueryParameters(withRouter(Recommendations));
