import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post8 = () => {
  const classes = useStyles();
  return <main className="content" id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/salary-negotiation-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/salary-negotiation-tips`}
        >
          Salary Negotiation Tips
        </Link>
      </Typography>
    </div>
    <article aria-label="5 Ways to Show Your Worth and Keep Your Job">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 466 }}
          src="https://advice.j2c.com/wp-content/uploads/2020/04/5-Ways-to-Show-Your-Worth-and-Keep-Your-Job2.png"
          alt="Woman working on laptop"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/jmillermerrell`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/jmillermerrell`}
            rel="author"
          >
            Jessica Miller-Merrell
          </Link>
          &nbsp;/&nbsp;
          <time>April 16, 2020</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          5 Ways to Show Your Worth and Keep Your Job
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          In our current economic climate, unless
          your company is deemed essential, you might be facing layoffs or
          furloughs until we get to the other side of the current worldwide
          crisis. Despite this economic uncertainty, there are some things you
          can
          do to show your value so that you’re part of a core group that keeps
          your company operating through reorganization or furloughs.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Have specific goals in mind.
        </Typography>
        <Typography variant="body1" gutterBottom>Your manager is not
          a mind reader. He or she may also not have time - as crisis management
          meetings with the executive team become a top priority - to set your
          goals for you. In our current climate, consider what your career might
          look like in a post-pandemic world. Ask yourself:
        </Typography>

        <ul>
          <li><Typography variant="body1" gutterBottom>Can you support essential
            company functions?</Typography></li>
          <li><Typography variant="body1" gutterBottom>
            Are you really good at something that you haven’t accessed in a
            while as part of your daily job functions?
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>How can you insert
            yourself into these areas?</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          Do some research and see how other companies in your industry are
          handling communications, precautions, best practices, and so on.
        </Typography>
        <figure className="wp-block-image size-large is-resized">
          <img
            className={classes.img}
            src="https://advice.j2c.com/wp-content/uploads/2020/04/shutterstock_1022748394-scaled.jpg"
            alt="woman using linkedin on mobile and laptop economic uncertainty"
          />
        </figure>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Update your LinkedIn profile (or get one).
        </Typography>
        <Typography variant="body1" gutterBottom>
          Worst case scenario, you’re one of the employees to get laid off or
          furloughed. Right after you file for unemployment, immediately update
          your LinkedIn profile (or set one up if you don't already have
          one).
        </Typography>
        <Typography variant="body1" gutterBottom>
          Companies <em>are</em> hiring right now, but they may be
          looking for different skills than you have on your profile right now.
          Time for a refresh. Start with your photo (make it a professional and
          recent one), and work your way down. What new skills have you added?
          Does your LinkedIn bio read like an outdated resume intro, or does it
          showcase your brilliant personality? Do your job responsibilities
          include your impact on company key performance indicators (KPIs)?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Read your profile like you’re considering hiring yourself. What are
          you missing that someone in HR might want to know? <a
          href="https://advice.j2c.com/explain-gap-employment-history/"
          target="_blank" rel="noreferrer noopener">Explain any gaps in
          employment.</a> It can help to have a friend, even if they’re not in
          your industry, look over your LinkedIn profile. Pick someone who will
          give you honest feedback. We know ourselves so well, we often cannot
          spot weaknesses in our job history, education, or skills. Think about
          how you would succinctly describe your past job experiences in an
          interview and edit your profile with those things in mind.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Showcase your skills and experiences.
        </Typography>
        <Typography variant="body1" gutterBottom>
          What have you learned in your current job? Sometimes we get caught up
          in our day to day work, we forget about what we’ve learned until it’s
          time for a performance review. Keep a document and set a reminder to
          update it biweekly. Include your wins, new skills you’ve learned, your
          successful impact on a team or department goal. This doesn’t just help
          you remember 12 months worth of work in your annual performance
          review; it’s documentation you can use to update your LinkedIn
          profile, to share with your manager during one-on-one update meetings,
          and identify areas for improvement.
        </Typography>
        <figure className="wp-block-image size-large">
          <img
            className={classes.img}
            style={{ maxWidth: 2560, maxHeight: 1440 }}
            src="https://advice.j2c.com/wp-content/uploads/2020/04/shutterstock_446643151-scaled.jpg"
            alt="man and boss speaking economic uncertainty"
          />
        </figure>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Be direct with your boss about your intentions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Most people who are successful at work are successful for a
          reason. They work hard, they bring in new accounts, they made two
          million dollars for the company last year, or they are the “go-to”
          person for just about everything. I’ve yet to meet any successful
          person in the business world who said they “lucked into” their job, or
          that they happened to be in the right place at the right time. Yes,
          luck can be a factor in one’s success, but the business world isn’t
          the lottery. You can’t just sit back and cross your fingers, hoping
          that you picked the right combination of numbers to land yourself a
          windfall.&nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          The one thing successful people have in common?
          An open dialogue with their manager or supervisor about their career
          goals. You don’t have to slide into your boss’s office on day two of
          your new job to discuss a path to promotion. What you can do during
          your performance reviews is actively seek feedback, not just for your
          current role, but on what skills you need to move into a role with
          more responsibilities. A lot of people have a hard time doing this,
          but put yourself in your manager’s shoes. He or she is thinking about
          their own career development and is likely to have an eye on the next
          rung on the corporate ladder. But they can’t move up unless someone is
          ready to fill their role. <a
          href="https://advice.j2c.com/6-tips-strengthen-boss-relationship/"
          target="_blank" rel="noreferrer noopener">Follow these 6 tips to
          strengthen your relationship with your boss.</a>
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Be a team player.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Related to #4, one of the secrets to
          showing your value is taking on challenges without being asked—you do
          the work, prove yourself, then have the leverage needed to get a raise
          or promotion. This is where you sign up for new responsibilities and
          step up to handle the difficult things that will make your boss’s job
          easier. It can feel like thankless work but know that it makes an
          impression and your manager is much more likely to see you as an
          apprentice than a direct report. Check out our post on the <a
          rel="noreferrer noopener"
          href="https://advice.j2c.com/9-ways-to-be-an-amazing-employee/"
          target="_blank">9 Ways to Be an Amazing Employee</a> for more ideas.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you follow all of these recommendations, you will be in a good
          place to keep your job as we remain in this time of economic
          uncertainty, and if for any reason you are still included in a layoff
          or are furloughed, you'll also be in a good place to begin the job
          search. Just hang in there, work hard, and show your worth whenever
          possible.
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/salary-negotiation-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/salary-negotiation-tips`}
            rel="category tag"
          >
            Salary Negotiation Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post8;
