import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import NotFoundImage from '../common/NotFoundImage';
import BlogPageLayout from './BlogPageLayout';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    textAlign: 'center',
  },
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <BlogPageLayout>
      <div className={classes.pageContent}>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          404 Not Found
        </Typography>
        <NotFoundImage />
        <Button component={Link} to={'/'} color="primary" variant="contained">
          Back to Home
        </Button>
      </div>
      <br />
      <br />
    </BlogPageLayout>
  );
};

export default NotFoundPage;
