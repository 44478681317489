import queryString from 'query-string';

export const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * From https://www.w3resource.com/javascript/form/email-validation.php
 * @param email
 * @returns {boolean}
 */
export const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email);

export const loadPixelsAfterRegistration = () => {
  const j2cImg = document.createElement('img');
  j2cImg.src = 'https://www.jobs2careers.com/conversion.php?p=6779';
  j2cImg.width = 1;
  j2cImg.height = 1;
  document.body.append(j2cImg);
  const upwardImg = document.createElement('img');
  upwardImg.src = 'http://l5srv.net/AdServer/convert.ads?aid=Mjk=';
  upwardImg.width = 0;
  upwardImg.height = 0;
  document.body.append(upwardImg);
};

/**
* @param {string} query The query string, like ?a=b, usually window.location.search.
* @returns {*}
*/
export const parseQueryString = (query) => queryString.parse(query, { arrayFormat: 'bracket' /* avoid duplicate parameters */ });
