import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MUILink from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

import environment from '../../configs/environment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    paddingLeft: 48,
    width: '100%',
  },
  link: {
    color: theme.palette.common.black,
  },
}));

export default function MenuBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color={'transparent'} elevation={0}>
      <Toolbar>
        <div className={classes.logo}>
          <Link to={'/'}>
            <Logo />
          </Link>
        </div>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="primary"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              to={'/'}
              onClick={handleClose}
            >
              Home
            </MenuItem>
            <MenuItem
              component={Link}
              to={'/blog'}
              onClick={handleClose}
            >
              Blog
            </MenuItem>
            <MenuItem
              className={classes.link}
              component={MUILink}
              href={environment.talroo_terms_page}
              target="_blank"
              onClick={handleClose}
            >
              Terms
            </MenuItem>
            <MenuItem
              className={classes.link}
              component={MUILink}
              href={environment.talroo_ccpa_dns_page}
              target="_blank"
              onClick={handleClose}
            >
              Do Not Sell
            </MenuItem>
            <MenuItem
              className={classes.link}
              component={MUILink}
              href={environment.talroo_privacy_page}
              target="_blank"
              onClick={handleClose}
            >
              Privacy Policy
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
