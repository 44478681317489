import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Job from './widgets/Job';

const useStyles = makeStyles((theme) => ({
  slider: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
  },
  slickPrev: {
    [theme.breakpoints.up('sm')]: {
      left: -70,
    },
    [theme.breakpoints.down('xs')]: {
      left: 25,
    },
  },
  slickNext: {
    [theme.breakpoints.up('sm')]: {
      right: -70,
    },
    [theme.breakpoints.down('xs')]: {
      right: 25,
    },
  },
  slickButton: {
    boxShadow: 'none',
    zIndex: 5,
    lineHeight: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '50%',
      display: 'block',
      padding: 0,
      transform: 'translate(0, -50%)',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: -72,
      display: 'block',
      padding: 0,
    },
    cursor: 'pointer',
    fontSize: theme.typography.h3.fontSize,
    border: 'none',
    outline: 'none',
  },
}));

const NextArrow = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Fab
      color="primary"
      aria-label="next slide"
      className={`${classes.slickButton} ${classes.slickNext}`}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </Fab>
  );
};

const PrevArrow = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Fab
      color="primary"
      aria-label="previous slide"
      className={`${classes.slickButton} ${classes.slickPrev}`}
      onClick={onClick}
    >
      <NavigateBeforeIcon />
    </Fab>
  );
};

const JobsSlider = ({ jobs, handleJob, slider }) => {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings} className={classes.slider}>
      {jobs.map((job) => (
        <Job
          id={job.id}
          title={job.title}
          company={job.company}
          loc={job.city[0]}
          logoUrl={job.logo_url}
          jobUrl={job.url}
          key={`${job.title}${job.id}`}
          dataGaType="UID_Job_Click"
        />
      ))}
    </Slider>
  );
};

JobsSlider.propTypes = {
  jobs: PropTypes.array.isRequired,
  handleJob: PropTypes.func.isRequired,
};

export default JobsSlider;
