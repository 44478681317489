import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState, useEffect } from 'react';

import withQueryParameters from '../../hocs/withQueryParameters';
import { buildResultsUrl } from '../../utils/buildUrl';
import PositionContext from '../common/PositionContext';
import JobsWidget from '../widgets/JobsWidget';
import BasicPageLayout from './BasicPageLayout';
import PopAd from '../common/PopAd';

import environment from '../../configs/environment';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
}));

const RegPathPage = ({ history, showAd}) => {

  const context = useContext(PositionContext);
  const { position, zipCode} = context.state;
  const classes = useStyles();

  const [jobsDone, setJobsDone ] = useState(false)
  const [exitPosition, setExitPosition] = useState(null)
  const [lastPageAd, setLastPageAd] = useState(undefined)

  useEffect(() =>{
    const { reg_path_ads: regPathAds=[] } = environment;
    if(showAd>=0){
      const regPathAd = regPathAds[showAd]
      if (regPathAd && regPathAd.type !== 'none'){
        setLastPageAd(regPathAd);
      }      
    }
  }, [showAd]);

  useEffect(() => {
    if(jobsDone){
      if (!lastPageAd){
        setExitPosition(position);
      }
    }
  }, [jobsDone, lastPageAd, position]);

  useEffect(() => {
    if (exitPosition) {
      context.updateValue('submitPosition', true);
      history.push(buildResultsUrl(exitPosition, zipCode));
    }
  }, [exitPosition, context, zipCode, history]);

  const jobsWidgetProps = {
    ad_props: {
      search_type: 'DefaultSearch',
      display_type: 'Carousel',
      related_count: 3,
    },
    loc: zipCode,
    keyword: 'Warehouse',
    limit: 1,
    done: () => setJobsDone(true)
  };

  return (
    <BasicPageLayout>

      {!jobsDone && 
        <>
          <Typography variant="h4" className={classes.header}>
            Urgent Hiring Jobs Near You
          </Typography>
          <JobsWidget {...jobsWidgetProps} />
        </>
      }
      
      {jobsDone && lastPageAd &&
        <div>
          { lastPageAd && <Typography variant="h4" className={classes.header}>
            {lastPageAd.title}
          </Typography>
          }
          <PopAd 
            id={lastPageAd.id}
            cid={lastPageAd.cid}
            onKeywordClick={setExitPosition}
          />
        </div>
      }
      <Button
        component={Link}
        href={buildResultsUrl(position, zipCode, 0, { bypass: 1 })}
        size="small"
        aria-label="to search results"
        data-ga-type="RegPath_SkipButton"
        data-ga-content="Skip"
      >
        Skip
      </Button>
    </BasicPageLayout>
  );
};

export default withQueryParameters(RegPathPage);
