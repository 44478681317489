import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';

import api from '../apis/api';
import themes from '../assets/themes';
import PositionContext from './common/PositionContext';
import JobContainer from './JobContainer';

const useStyles = makeStyles((theme) => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    fontWeight: 'bold',
  },
  messageHolder: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backgroundColor: '#DBD1E2',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const CompanyJobs = ({ handleJob }) => {
  const context = useContext(PositionContext);
  const classes = useStyles();
  const {
    zipCode,
    company,
    position,
    companyJobs,
    companyTitle,
  } = context.state;
  const [hasJobs, setHasJobs] = useState(true);

  useEffect(() => {
    if (company in themes) {
      const searchApiCredential = themes[company].search_api_info;
      api.getCompanyJobs(
        searchApiCredential.id,
        searchApiCredential.password,
        position,
        zipCode,
      ).then((response) => {
        if (response.length === 0) {
          setHasJobs(false);
        }
        context.updateValue('companyJobs', response);
      }).catch((error) => {
        setHasJobs(false);
        throw error;
      });
    }
  // eslint-disable-next-line
  }, [zipCode, position, company]);

  let component = null;

  if (companyJobs.length > 0 && company) {
    component = (
      <>
        <Typography variant="h5" className={classes.centered}>
          {companyTitle} Jobs Near You
        </Typography>
        <div>
          {companyJobs.map((job) =>
            <JobContainer
              key={`${job.title}${job.id}company`}
              job={job}
              handleJob={handleJob}
            />,
          )}
        </div>
      </>
    );
  } else if (!hasJobs) {
    component = <Paper elevation={0} className={classes.messageHolder}>
      <Typography variant="body1">
        We were unable to find any open positions
        at <strong>{companyTitle}</strong> in your area. Check out these other
        great job matches we have for you.
      </Typography>
    </Paper>;
  }

  return component;
};

export default CompanyJobs;
