import React, { useEffect, useState } from 'react';
import recommendApis from '../apis/recommendApis';


export const withCompanies = (WrappedComponent) => (props) => {
  const [newCompanies, setCompanies] = useState([]);
  const { ad_props = {}, loc } = props;
  const { search_type } = ad_props;

  useEffect(() => {
    switch (search_type) {
      case 'CompaniesByLocation':
        if (loc) {
          recommendApis.getCompaniesByLocation(loc).then((response) => {
            if ((typeof response) === 'string') {
              // Do nothing, bad response from API endpoint.
            } else {
              const { companies } = response;
              setCompanies(companies);
            }
          });
        }
        break;
      default:
        console.log('No `search_type` defined.');
        break;
    }
  }, [search_type, loc]);

  return <WrappedComponent companies={newCompanies} {...props} />;
};
