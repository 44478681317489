import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from 'prop-types';
import React, { useContext } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import PositionContext from './common/PositionContext';
import JobsSlider from './JobsSlider';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'left',
    paddingRight: theme.spacing(4),
  },
  dialogContent: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(12),
    },
  },
  dialogContentHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogTitleHolder: {
    fontWeight: 'bold',
    display: 'block',
  },
  closeButtonHolder: {
    display: 'inline-block',
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
  },
}));

const Transition = React.forwardRef((props, ref) =>
  <Slide direction="up" ref={ref} {...props} />);

const JobsDialog = ({ handleJob, openFlagName, title, jobs }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const context = useContext(PositionContext);
  const { [openFlagName]: open } = context.state;

  const handleClose = () => {
    context.updateValue(openFlagName, false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      fullScreen={!matches}
      maxWidth="sm"
      onClose={() => handleClose()}
    >
      <DialogTitle id="dialog-title" className={classes.header}>
        <div className={classes.closeButtonHolder}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
            data-ga-type="Close_Dialog"
            data-ga-content={title}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogTitleHolder}>
          <Typography variant="h5">{title}</Typography>
        </div>
        <br />
        <div className={classes.dialogContentHolder}>
          <JobsSlider jobs={jobs} handleJob={handleJob} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

JobsDialog.propTypes = {
  handleJob: PropTypes.func.isRequired,
  openFlagName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  jobs: PropTypes.array.isRequired,
};

export default JobsDialog;
