import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet/es/Helmet';

import environment from '../configs/environment';

const JobsDisplayPop = () => {
  useEffect(() => {
    (window.TalrooAds = window.TalrooAds || []).push({});
  }, []);

  return (
    <>
      <Helmet>
        <link rel="manifest" href="/manifest.json" />
        <script
          src={`https://api.pushnami.com/scripts/v1/push/${environment.pushnami_id}`}
        >
        </script>
      </Helmet>
      <ins
        className="talrooads"
        style={{ display:'inline-block', width:'100%', height:'100vh' }}
        scroll="true"
        data-ad-cid={environment.job_display.ad_cid}
        data-ad-id={environment.job_display.ad_id}>
      </ins>
    </>
  );
};

export default JobsDisplayPop;
