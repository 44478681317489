import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import Cookies from 'js-cookie';
import * as PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { buildResultsUrl } from '../utils/buildUrl';
import PositionContext from './common/PositionContext';

const useStyles = makeStyles((theme) => ({
  textField: {
    border: 0,
    backgroundColor: theme.palette.common.white,
  },
  cssOutlinedInput: (props) => ({
    height: 40,
    borderRadius: 0,
  }),
  itemHolder: {
    padding: theme.spacing(1),
  },
}));

const SearchForm = ({ history, keyword, loc, getNewJobs, onSubmit }) => {
  const context = useContext(PositionContext);
  const [queryValue, setQueryValue] = useState(keyword);
  const [locValue, setLocValue] = useState(loc);
  const classes = useStyles();
  const { zipCode } = context.state;

  useEffect(() => {
    setQueryValue(keyword);

    if (!loc && zipCode) {
      setLocValue(zipCode);
    } else {
      setLocValue(loc);
    }
  }, [keyword, loc, zipCode]);

  const handleChange = (e) => {
    const { target } = e;
    const { value, name } = target;

    if (name === 'position-text-field') {
      setQueryValue(value);
    } else if (name === 'location-text-field') {
      setLocValue(value);
    }
  };

  const handleSubmit = () => {
    // TODO: These might be bad if the search fails.
    Cookies.set('position', queryValue);
    context.updateValue('position', queryValue);

    // TODO: These might be bad if the search fails.
    Cookies.set('zip', locValue);
    context.updateValue('zipCode', locValue);
    context.updateValue('companyJobs', []);
    history.push(buildResultsUrl(queryValue, locValue));
    getNewJobs(queryValue, locValue, 0);
    onSubmit();
  };

  const qTextFieldProps = {
    id: 'position-text-field',
    name: 'position-text-field',
    fullWidth: true,
    size: 'small',
    className: classes.textField,
    onChange: handleChange,
    InputProps: {
      startAdornment: (<InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>),
      classes: {
        root: classes.cssOutlinedInput,
      },
    },
    variant: 'outlined',
    value: queryValue,
  };

  const lTextFieldProps = {
    id: 'location-text-field',
    name: 'location-text-field',
    fullWidth: true,
    size: 'small',
    className: classes.textField,
    onChange: handleChange,
    InputProps: {
      startAdornment: (<InputAdornment position="start">
        <LocationOnIcon />
      </InputAdornment>),
      classes: {
        root: classes.cssOutlinedInput,
      },
    },
    variant: 'outlined',
    value: locValue,
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={4} className={classes.itemHolder}>
          <TextField {...qTextFieldProps} />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.itemHolder}>
          <TextField {...lTextFieldProps} />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.itemHolder}>
          <Button
            fullWidth
            color="primary"
            variant={'contained'}
            size="large"
            disableElevation
            className={classes.root}
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

SearchForm.propTypes = {
  keyword: PropTypes.string,
  loc: PropTypes.string,
};

SearchForm.defaultProps = {
  keyword: '',
  loc: '',
};

export default withRouter(SearchForm);
