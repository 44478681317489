import cityStateList from './assets/city_state_locations.json';
import geoList from './assets/geo_locations.json';
import zipCodeList from './assets/zip_code_locations.json';

export const geoToLocation = (geo) =>
  geo in geoList ? geoList[geo] : '';

export const zipCodeToCityState = (zipCode) =>
  cityStateList[zipCodeList[parseInt(zipCode)]];

export const cityStateToZipCode = (cityState) => {
  const cityStateIndex = cityStateList.indexOf(cityState);
  return Object.keys(zipCodeList).filter(
    (zipCode) => zipCodeList[zipCode] === cityStateIndex,
  );
};

export const isValidZipCode = (zip) => /^\d{4,5}?$/.test(zip);

export const formatZipCode = (rawZipCode) =>
  rawZipCode.toString().padStart(5, '0');
