import React from 'react';

import environment from '../configs/environment';
import JobsDisplayPop from './JobsDisplayPop';
import JobsDisplayDefault from './JobsDisplayDefault';

const JobsDisplay = ({ q, l, start, t1 = null }) => {
  return environment.job_display.use_pop ? <JobsDisplayPop /> : <JobsDisplayDefault q={q} l={l} start={start} t1={t1} />;
};

export default JobsDisplay;
