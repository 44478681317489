import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

/*
 * React Component that wraps a POP Ad Unit
 */
const PopAd = (props) => {
  const { onKeywordClick } = props
  const {pathname, search} = props.location;

  const insElmtRef = useRef(null)

  useEffect(() => {
    // Reduce reload calls in POP by checking if iframe is present
    const hasIframe = insElmtRef.current.children.length > 0;
    !hasIframe && (window.TalrooAds = window.TalrooAds || []).push({});
  });

  useEffect(()=> {
    if (onKeywordClick){
      // Configure event listeners
      const onKeywordClickHandler = (event) => {
        const { keyword } = event.detail;
        onKeywordClick(keyword);
      };

      const insElmt = insElmtRef.current;
      insElmt.addEventListener('talroo-ad-keyword-click', onKeywordClickHandler);

      return () => {
        insElmt.removeEventListener('talroo-ad-keyword-click', onKeywordClickHandler);
      }
    }
  }, [onKeywordClick])

  return (
    <div key={`${pathname}${search}`}>
      <ins ref={insElmtRef} 
        className={`${props.className} talrooads`}
        style={props.style}
        data-ad-id={props.id}
        data-ad-cid={props.cid}>
      </ins>
    </div>
  );
};

PopAd.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  id: PropTypes.string.isRequired,
  cid: PropTypes.string.isRequired,
  onKeywordClick: PropTypes.func
};

PopAd.defaultProps = {
  className: '',
  style: { display: 'block'}
};

export default withRouter(PopAd);
