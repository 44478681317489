import React from 'react';

const authors = {
  'charles-ebert': {
    name: 'Charles Ebert',
    image: 'https://secure.gravatar.com/avatar/06fdaab77f7f1547f2a5f389771fc697?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Charles is a career mentor, motivational speaker &amp; human resources
      consultant with over 10 years of experience in HR sector. Charles is a
      lead expert at&nbsp;
      <a href="https://professionalresumesolutions.com/">
        Professional Resume Solutions
      </a>. Apart from career mentoring, he loves photography and football.
    </span>,
    posts: [
      'sure-quit-four-reasons-stay',
    ],
  },
  'alex': {
    name: 'Alexandra Mankar',
    image: 'https://secure.gravatar.com/avatar/b71f92d444ae88b097fe122bc83031f2?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Alex is a Tucson-raised, Austin-based brunch aficionado. She enjoys a
      solid cup of coffee and browsing used record stores.
    </span>,
    posts: [
      '7-tips-to-ace-a-virtual-interview',
      'boost-productivity-working-home',
    ],
  },
  'felixt': {
    name: 'Felix Tarcomnicu',
    image: 'https://secure.gravatar.com/avatar/75e812dcd5ed9d6a2d14ff465a754ac0?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Felix Tarcomnicu is the CEO &amp; Founder of <a
      href="https://www.resumeok.com/">ResumeOK</a>, a website that’s on a
      mission to help job seekers learn <a
      href="http://www.resumeok.com/how-to-write-a-resume/">how to write a
      good resume</a>.
    </span>,
    posts: [
      'show-remote-work-experience-on-a-resume',
    ],
  },
  'nkhaykis': {
    name: 'Natalya Khaykis',
    image: 'https://secure.gravatar.com/avatar/2f26c217a9a888b2fd6275c3cbe153fe?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Natalya is a
      writer and analyst at <a href="https://www.zipjob.com/"
                               rel="nofollow">ZipJob</a>. She graduated from
      NYU with a masters and currently resides in New York City. She covers
      topics related to resume writing and job search. In her free time
      you'll
      find Natalya attempting to cook an edible meal, reading and spending
      time with her husband.
    </span>,
    posts: [
      '7-things-never-include-resume',
    ],
  },
  'travis': {
    name: 'Travis Brown',
    image: 'https://secure.gravatar.com/avatar/dc231c04e294e7d18b2a4eb55bbf87d4?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Travis is a
      frontend developer and digital marketer with Talroo. He's taken web
      development ideas from concept to reaching millions of users around
      the country. He started with computers in the 80's, the internet on BBS'
      in the early 90's and has been in the tech industry professionally for
      over 10 years. When he's not busy at work, you'll find him experimenting
      with fun, exciting, and challenging new web development endeavors.
    </span>,
    posts: [
      'resume-sections-guide',
    ],
  },
  'markdyson': {
    name: 'Mark Anthony Dyson',
    image: 'https://secure.gravatar.com/avatar/26bfed0815381be55de5a1b3985491b4?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Mark Anthony Dyson is a career consultant, job seeker advocate, career
      writer, and
      founder of <a href="http://thevoiceofjobseekers.com/">The Voice of Job
      Seekers</a>. He helps the employed, unemployed, underemployed, and
      under-appreciated find jobs. Mark has published more than 400 articles
      on his blog as well as some of the largest career sites such as
      Recruiter.com, YouTern, and Come Recommended.
    </span>,
    posts: [
      'master-interview-job-search',
    ],
  },
  'jmillermerrell': {
    name: 'Jessica Miller-Merrell',
    image: 'https://secure.gravatar.com/avatar/689486d142faaefd0d847c8be82f2ac7?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Jessica Miller-Merrell is workplace change agent and author focused on
      human
      resources and talent acquisition living in Austin, TX. Recognized by
      Forbes as a top 50 social media influencer, she's the founder of
      Workology and host of the Workology Podcast.
    </span>,
    posts: [
      '5-ways-to-show-your-worth-and-keep-your-job',
    ],
  },
  'brynhuntpalmer': {
    name: 'Bryn Huntpalmer',
    image: 'https://secure.gravatar.com/avatar/23dfcace6e8449e90da0214559022ee2?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Bryn Huntpalmer lives in Austin, Texas, where she currently works as
      editor-in-chief of <a href="https://www.modernize.com/"
                            rel="nofollow">Modernize</a>. She writes about
      energy efficiency and sustainable living with the goal of empowering
      homeowners with the expert guidance and educational tools they need to
      take on big home projects with confidence.
    </span>,
    posts: [
      'can-create-green-workspace',
    ],
  },
  'evawislow': {
    name: 'Eva Wislow',
    image: 'https://secure.gravatar.com/avatar/85e6d59f1755c69218712f16d39514d8?s=160&amp;d=mm&amp;r=g',
    desc: <span>
      Eva Wislow is an entrepreneur and HR expert from Pittsburgh. She is
      focusing on helping people break down their limits and achieve
      career success. Eva finds her inspiration in writing and peace of
      mind through yoga. Follow <a href="https://medium.com/@eva.wislow">Eva's
      blog</a> or connect with her on Twitter.
    </span>,
    posts: [
      '5-important-career-lessons-smart-people-tend-forget',
    ],
  },
};

export default authors;
