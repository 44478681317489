import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Header from '../Header';
import BasicPageLayout from './BasicPageLayout';

const useStyles = makeStyles((theme) => ({
  contentHolder: {
    padding: theme.spacing(0),
  },
}));

const CaptureFunnelPage = ({ children }) => {
  const classes = useStyles();
  return (
    <BasicPageLayout>
      <div className={classes.contentHolder}>
        <Header />
        {children}
      </div>
    </BasicPageLayout>
  );
};

export default CaptureFunnelPage;
