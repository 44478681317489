import { Grid } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';

import authors from '../blog/Authors';
import posts from '../blog/Posts';
import Author from '../common/Author';
import BlogPostListItem from '../common/BlogPostListItem';
import BlogPageLayout from './BlogPageLayout';

const BlogAuthorPage = ({ match }) => {
  const authorSlug = match.params['author'];
  let component = <Redirect to='/404' />;

  if (authorSlug in authors) {
    component = (
      <BlogPageLayout>
        <Author
          name={authors[authorSlug].name}
          image={authors[authorSlug].image}
          desc={authors[authorSlug].desc}
          slug={authorSlug}
        />
        <hr />
        <Grid>
          {authors[authorSlug].posts.map((postSlug) => {
            const post = posts[postSlug];
            return (
              <Grid item key={postSlug}>
                <BlogPostListItem
                  slug={postSlug}
                  title={post.title}
                  image={post.image}
                  desc={post.desc}
                  authorSlug={post.author}
                  date={post.date}
                />
              </Grid>
            );
          })}
        </Grid>
      </BlogPageLayout>
    );
  }

  return component;
};

export default BlogAuthorPage;
