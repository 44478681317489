import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';
import Cookies from 'js-cookie';
import * as md5 from 'md5';
import React, { useContext, useState } from 'react';
import api from '../../apis/api';
import themes from '../../assets/themes';
import { isValidEmail, loadPixelsAfterRegistration } from '../../utils/commonUtils';
import { isValidZipCode } from '../../utils/locationUtils';

import PositionContext from '../common/PositionContext';

const useStyles = makeStyles((theme) => ({
  formItem: {
    textAlign: 'left',
    borderRadius: theme.spacing(0.5),
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  inputLabel: {
    color: theme.palette.common.white,
  },
  formBox: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  errorMessage: {
    color: theme.palette.common.white,
  },
}));

const getPositions = (company) => {
  const companyTheme = company && company in themes ? company : 'main';
  const positions = [];

  for (const pos in themes[companyTheme].queries) {
    const query = themes[companyTheme].queries[pos].query;
    positions.push(
      {
        searchTitle: query.toLowerCase(),
        displayName: query,
        dataGaContent: query,
      },
    );
  }
  return positions;
};

const Profile2Form = ({ company, submitCallback }) => {
  const context = useContext(PositionContext);
  const { googleLocation } = context.state;
  const classes = useStyles();
  const positions = getPositions(company);
  const [email, setEmail] = useState('');
  const [zipCode, setZipCode] = useState(googleLocation);
  const [position, setPosition] = useState(positions[0].searchTitle);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setErrorMessage('');
    const { target } = e;
    const { value, name } = target;

    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'zipCode':
        setZipCode(value);
        break;
      case 'positionPicker':
        setPosition(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (!zipCode) {
      setErrorMessage('You must enter a zip code.');
    } else if (!email) {
      setErrorMessage('You must enter an email.');
    } else if (!isValidZipCode(zipCode)) {
      setErrorMessage('You must enter a valid zip code.');
    } else if (!isValidEmail(email)) {
      setErrorMessage('You must enter a valid email.');
    } else {
      Cookies.set('zip', zipCode);
      context.updateValue('zipSubmit', true);
      context.updateValue('zipCode', zipCode);

      Cookies.set('uid', md5(email));
      Cookies.set('email', email);
      context.updateValue('uid', md5(email));
      context.updateValue('email', email);

      context.updateValue('position', position);

      if (email) {
        api.subscribeContact(email, position, zipCode).then(() => {
        }).finally(() => {
          loadPixelsAfterRegistration();
        });
      }

      submitCallback();

      const scroll = document.getElementById('JobHeader');
      if (scroll) {
        scroll.scrollIntoView({ block: 'end', inline: 'nearest' });
      }
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className={classes.formBox}>
      <form onSubmit={onFormSubmit}>
        <InputLabel htmlFor="zipCode">
          <Typography variant="h6" className={classes.inputLabel}>
            Get Hired Quickly!
          </Typography>
        </InputLabel>
        <FormControl variant="outlined" fullWidth size="small">
          <Select
            className={classes.formItem}
            id="positionPicker"
            name="positionPicker"
            value={position}
            onChange={handleChange}
          >
            {positions.map(
              (pos) => <MenuItem key={pos.searchTitle} value={pos.searchTitle}>
                {pos.displayName}
              </MenuItem>)}
          </Select>
        </FormControl>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <RoomIcon />
              </InputAdornment>
            ),
          }}
          className={classes.formItem}
          id="zipCode"
          name="zipCode"
          defaultValue={zipCode}
          autoFocus
          onChange={handleChange}
          variant="outlined"
          fullWidth
          size="small"
          color="secondary"
          placeholder="Zipcode"
        />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          }}
          className={classes.formItem}
          autoFocus
          name="email"
          onChange={handleChange}
          value={email}
          variant="outlined"
          fullWidth
          size="small"
          color="secondary"
          placeholder="Email Address"
        />
        <div>
          <Typography variant="subtitle2" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </div>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          data-ga-type="Email_Submit"
          data-ga-content="True"
          fullWidth
          type="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default Profile2Form;
