import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import logo from '../../assets/logo.png';
import { siteName } from '../../assets/themes';

const useStyles = makeStyles((theme) => ({
  logoHolder: {
    width: '100%',
    height: 75,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 75,
    paddingTop: theme.spacing(1),
  },
}));

const Logo = () => {
  const classes = useStyles();

  return (
    <div className={classes.logoHolder}>
      <img
        alt={`${siteName} Logo`}
        className={classes.logo}
        src={logo}
      />
    </div>
  );
};

export default Logo;
