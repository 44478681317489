import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import posts from '../blog/Posts';
import BlogPostListItem from '../common/BlogPostListItem';
import BlogPageLayout from './BlogPageLayout';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  postImage: {
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      paddingLeft: theme.spacing(0),
    },
    float: 'right',
    textAlign: 'right',
    maxWidth: '100%',
    paddingLeft: theme.spacing(4),
  },
  postHolder: {
    display: 'table',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  postHeader: {
    display: 'block',
  },
}));

const PostsPage = () => {
  const classes = useStyles();

  return (
    <BlogPageLayout>
      <Typography variant="h1" className={classes.pageTitle} gutterBottom>
        Latest Blog Posts
      </Typography>
      <hr />
      <Grid>
        {Object.keys(posts).map((postSlug) => (
          <Grid item key={postSlug}>
            <BlogPostListItem
              slug={postSlug}
              title={posts[postSlug].title}
              image={posts[postSlug].image}
              desc={posts[postSlug].desc}
              authorSlug={posts[postSlug].author}
              date={posts[postSlug].date}
            />
          </Grid>
        ))}
      </Grid>
    </BlogPageLayout>
  );
};

export default PostsPage;
