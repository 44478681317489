import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';

const MobileSearchDialog = ({ children, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="mobile-search-dialog"
      open={open}
    >
      {children}
    </Dialog>
  );
};

MobileSearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default MobileSearchDialog;
