import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import themes from '../../assets/themes';
import withQueryParameters from '../../hocs/withQueryParameters';
import { buildResultsUrl } from '../../utils/buildUrl';
import { zipCodeToCityState } from '../../utils/locationUtils';
import PositionContext from '../common/PositionContext';
import CompaniesWidget from '../widgets/companies/CompaniesWidget';
import BasicPageLayout from './BasicPageLayout';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  linkButtonHolder: {
    overflow: 'hidden',
    borderRadius: theme.spacing(4),
    textDecoration: 'none',
    color: theme.palette.text.primary,
    display: 'flex',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
  regularButtonHolder: {
    overflow: 'hidden',
    textTransform: 'none',
    borderRadius: theme.spacing(4),
    padding: 0,
    color: theme.palette.text.primary,
    display: 'flex',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
  buttonText: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
  },
  icon: {
    overflow: 'hidden',
  },
}));

const LinkButton = ({
  searchTitle,
  displayName,
  dataGaContent,
  zipCode,
  handleSubmit,
}) => {
  const classes = useStyles();
  return (
    <Link
      to={buildResultsUrl(searchTitle, zipCode)}
      className={classes.linkButtonHolder}
      onClick={() => handleSubmit(searchTitle)}
      data-ga-type="Position_Submit"
      data-ga-content={dataGaContent}
    >
      <Typography variant="body1" className={classes.buttonText}>
        {displayName}
      </Typography>
    </Link>
  );
};

const RegularButton = ({
  searchTitle,
  displayName,
  dataGaContent,
  handleSubmit,
}) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth
      className={classes.regularButtonHolder}
      onClick={() => handleSubmit(searchTitle)}
      data-ga-type="Position_Submit"
      data-ga-content={dataGaContent}
    >
      <Typography variant="body1" className={classes.buttonText}>
        {displayName}
      </Typography>
    </Button>
  );
};

const PositionPickerPage = ({ regPath, children, company = '' }) => {
  const context = useContext(PositionContext);
  const classes = useStyles();
  const { zipCode } = context.state;

  const handleSubmit = (title) => {
    context.updateValue('submitPosition', true);
    context.updateValue('position', title);
  };

  const companyTheme = company && company in themes ? company : 'main';
  const positions = [];

  for (const position in themes[companyTheme].queries) {
    const query = themes[companyTheme].queries[position]['query'];
    positions.push(
      {
        searchTitle: query.toLowerCase(),
        displayName: query,
        dataGaContent: query,
      },
    );
  }

  const companiesWidgetProps = {
    ad_props: {
      search_type: 'CompaniesByLocation',
      display_type: 'Carousel',
    },
    loc: zipCodeToCityState(zipCode),
    limit: 10,
  };

  return (
    <BasicPageLayout>
      <Typography variant="h4" className={classes.header}>
        What Kind of Position Are You Interested In?
      </Typography>
      {positions.map((position) => {
        let component = null;
        if (regPath) {
          component = <RegularButton
            key={position.displayName}
            searchTitle={position.searchTitle}
            displayName={position.displayName}
            dataGaContent={position.dataGaContent}
            handleSubmit={handleSubmit}
          />;
        } else {
          component = <LinkButton
            key={position.displayName}
            searchTitle={position.searchTitle}
            displayName={position.displayName}
            dataGaContent={position.dataGaContent}
            zipCode={zipCode}
            handleSubmit={handleSubmit}
          />;
        }
        return component;
      })}
      <br />
      <CompaniesWidget {...companiesWidgetProps} />
      {children}
    </BasicPageLayout>
  );
};

export default withQueryParameters(PositionPickerPage);
