import React from 'react';

const PositionContext = React.createContext({
  position: '',
  zipSubmit: false,
  submit: false,
  jobs: [],
  zipCode: '',
  uid: '',
  email: '',
  name: '',
  recommendations: [],
  submitPosition: false,
  relatedJobs: [],
  relatedOpen: false,
  emailOpen: false,
  profile2Open: false,
  company: '',
  uidJobs: [],
  uidOpen: false,
  companyJobs: [],
  companyTitle: '',
  googleLocation: '',
});

export default PositionContext;
