import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useRef } from 'react';

import api from '../../apis/api';
import CopyrightStatement from '../common/CopyrightStatement';
import MenuBar from '../common/MenuBar';
import PositionContext from '../common/PositionContext';
import EmailDialog from '../EmailDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const ResultsPage = ({ children, modalOption = 'emailOpen' }) => {
  const context = useRef(useContext(PositionContext));
  const classes = useStyles();

  useEffect(() => {
    let { uid, zipCode, googleLocation } = context.current.state;

    if (!zipCode && googleLocation) {
      Cookies.set('zip', googleLocation);
      context.current.updateValue('zipCode', googleLocation);
    }

    if (uid) {
      api.getUIDBehavior(uid, zipCode).then((response) => {
        if (response.length > 0) {
          context.updateValue('uidOpen', true);
          context.updateValue('uidJobs', response);
        }
      }).catch((error) => {
        console.log(error.message);
      });
    } else {
      // Disable the search modal on the homepage.
      if (window.location.pathname !== '/') {
        context.current.updateValue(modalOption, true);
      }
    }
  }, [context, modalOption]);

  return (
    <>
      <MenuBar />
      <Container maxWidth='md' className={classes.container} id="container">
        {children}
      </Container>
      <CopyrightStatement />
      <EmailDialog
        title="Find New Jobs As Soon As They Are Available!"
      />
    </>
  );
};

export default ResultsPage;
