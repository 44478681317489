import React from 'react';

import { withCompanies } from '../../../hocs/withCompanies';
import { CompaniesCarousel } from './CompaniesCarousel';

const CompaniesWidget = (props) => {
  const { ad_props } = props;
  const { display_type } = ad_props;

  switch (display_type) {
    case 'Carousel':
      return <CompaniesCarousel {...props} />;
    default:
      return <CompaniesCarousel {...props} />;
  }
};

export default withCompanies(CompaniesWidget);
