import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CopyrightStatement from '../common/CopyrightStatement';
import Logo from '../common/Logo';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
}));

const BasicPageLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Logo />
      <Container maxWidth="xs" className={classes.container}>
        {children}
      </Container>
      <CopyrightStatement />
    </>
  );
};

export default BasicPageLayout;
