import { makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { cityStateToZipCode } from '../../../utils/locationUtils';
import Company from './Company';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  slickPrev: {
    left: 25,
  },
  slickNext: {
    right: 25,
  },
  slickButton: {
    color: theme.palette.common.white,
    zIndex: 5,
    lineHeight: 0,
    position: 'absolute',
    bottom: -50,
    display: 'block',
    padding: 0,
    cursor: 'pointer',
    fontSize: theme.typography.h3.fontSize,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  slickSlider: {
    marginBottom: theme.spacing(8),
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const NextArrow = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab
      color="primary"
      size="small"
      aria-label="next slide"
      className={`${classes.slickButton} ${classes.slickNext}`}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </Fab>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  onClick: () => {
  },
};

const PrevArrow = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab
      color="primary"
      size="small"
      aria-label="previous slide"
      className={`${classes.slickButton} ${classes.slickPrev}`}
      onClick={onClick}
    >
      <NavigateBeforeIcon />
    </Fab>
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  onClick: () => {
  },
};

const filterDefaultLogos = (companies) =>
  companies.filter((companyData) =>
    !companyData['logoUrl'].startsWith('https://media.j2c.com/default'));

export const CompaniesCarousel = ({ companies, loc, title }) => {
  const classes = useStyles();
  let companyContent = null;
  const filteredCompanies = filterDefaultLogos(companies);

  if (filteredCompanies.length > 0) {
    const zipCode = cityStateToZipCode(loc);
    companyContent = filteredCompanies.slice(0, 6).map((company, index) => {
      const companyData = {
        key: `company${company.id}`,
        id: company.id,
        logoUrl: company.logoUrl,
        company: company.name,
        loc: zipCode[0],
      };
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Company {...companyData} />;
    });

    const settings = {
      adaptiveHeight: false,
      className: 'center',
      centerMode: false,
      infinite: true,
      dots: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      swipeToSlide: true,
      pauseOnHover: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    return (
      <>
        <Typography variant="h6" className={classes.header}>
          {title}
        </Typography>
        <Slider {...settings} className={classes.slickSlider}>
          {companyContent}
        </Slider>
      </>
    );
  } else {
    return null;
  }
};

CompaniesCarousel.propTypes = {
  companies: PropTypes.array.isRequired,
  loc: PropTypes.string,
  title: PropTypes.string,
};

CompaniesCarousel.defaultProps = {
  loc: '',
  title: 'Companies Hiring In Your Location',
};
