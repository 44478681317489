import React from 'react';
import { Route } from 'react-router-dom';

import withQueryParameters from '../../hocs/withQueryParameters';
import Profile3Page from '../profile3/Profile3Page';

const Profile3Route = ({ q, l, bypass, unsubscribeId, geo, ...rest }) => {
  const component = <Profile3Page />;

  return (
    <Route {...rest} render={() => component} />
  );
};

export default withQueryParameters(Profile3Route);
