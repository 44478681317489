import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post6 = () => {
  const classes = useStyles();
  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/interview-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/interview-tips`}
        >
          Interview Tips
        </Link>
      </Typography>
    </div>
    <article aria-label="5 Ways to Master the Interview Before Your Job Search">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 750, maxHeight: 465 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/04/interviewtest.png"
          alt="applicant test"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/markdyson`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/markdyson`}
            rel="author"
          >
            Mark Anthony Dyson
          </Link>
          &nbsp;/&nbsp;
          <time>March 18, 2020</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          5 Ways to Master the Interview Before Your Job Search
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          More than ever, job seekers must be proficient at interviewing. In
          fact, it's likely every networking opportunity is an essential job
          conversation. It may be the interaction to decide your next step, so
          you want to be interview ready.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The interview process is no longer a one and done meeting. &nbsp;In
          Lavie Margolin's latest book, <a
          href="https://www.amazon.com/dp/0692835555" target="_blank"
          rel="nofollow noopener noreferrer"><i>Mastering the Job Interview
          Process</i></a>, he states since employers have more applicants than
          ever, companies, "... are maximizing their time by scheduling more
          interviews online or by phone than they are in person." It is an "an
          extra hoop" for job candidates to go through, but it is common for
          professional positions to have a phone interview and a digital
          assessment before an in-person interview. &nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          But, it doesn’t have to feel like the Spanish Inquisition. Although
          these are easier to brace yourself for, the process and mentality are
          outdated. You'll be tested and often tried, so you'll need to be
          prepared.
        </Typography>
        <Typography variant="body1" gutterBottom>
          To impress hiring decision makers, doing and knowing enough to get by
          is what your competition is doing. You must be presentation ready at
          all times and in different ways.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You're thinking, "All I want is a job!" You don't have to say it. It
          shows. You know nothing about the interviewer, the company, or why you
          want the job. We can all tell. If you want a competitive edge, and
          want to know how and why to differentiate yourself, I've got you!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here are five reasons and strategies to implement:
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Employers want to see enthusiasm.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Interviewers throw around words indicating they want someone who is
          "passionate" about the job. In other words, they want job candidates
          that invested in the work. Sort of like dating where if you're not
          affectionate, then your heart is questioned.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Solution</b>: Margolin says, "...employers expect the job candidate
          will do research since the access to research has increased." You're
          not mastering the interview process if you don't check the company's
          website, look at press releases, or know the company's brand.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Employers want to see you add value.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You got the interview because you will add value. Now you're competing
          to show you add the most value. It's another reason to research, but
          it's also time to evaluate your natural strengths and skills and how
          they match that of the employers.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Solution</b>: You must clarify your value in particular ways with
          stories and results. Keep a running list of your accomplishments,
          transferable skills, and how you add value. The more you add to the
          list and how it applies to each employer you prepare for, the clearer
          your explanations to employers.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Employers will test you.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Some companies will test you through assessments, others will give you
          case scenarios for you to walk them through your solutions, and others
          will ask irreverent questions to see how you'll respond.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Solution</b>: You must be agile in your thinking to be ready for
          anything. Margolin suggests taking notes is necessary in most cases to
          show your interest in what the employer has to tell and show you're
          willing to learn.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Employers won't see fit without personality.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The interviewer will likely use their "gut instinct" rather than
          metrics to discern if you're a cultural fit for the organization. If
          he or she does not feel they know the candidate better, the
          interviewer will choose someone who is more personable and relatable.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Solution</b>: Showing personality is tough for job candidates but
          as Margolin says the strategy is to "Turn questioning into a
          conversation. Ask questions during the interviews, not at the end." I
          have stated in the past, companies can't train personality and showing
          that you're unique shows value.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Employers want to know that you want the job.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You're expected to sell yourself somewhat aggressively to show you
          have the passion for the job and the company. Most people usually
          undersell themselves even if they promise hard work, diligence, and
          timeliness.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Solution</b>: Margolin uses the analogy of an advertising company
          using a billboard approach and how in-your-face creativity doesn't
          promote you better. Margolin is right in suggesting over-the-top is
          not effective (although some have had success). Sprint's subtle
          approach in saying there's only a 1% difference between services, but
          their price is a profound value proposition. It's your job to make it
          your own.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Margolin also suggests the job candidate is responsible for moving the
          process forward by asking follow-up questions and sending thank-you
          notes. Most people think the last interview is the last contact
          necessary. Create several points of contact, timeline, and clear
          expectations of what is next for yourself and the employer. Without
          clarity, you set yourself up to be ignored or insignificant. Neither
          is good as you will need to ensure a good impression is all an
          employer experiences with you.
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/interview-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/interview-tips`}
            rel="category tag"
          >
            Interview Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post6;
