import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect } from 'react-router-dom';

import categories from '../blog/Categories';
import posts from '../blog/Posts';
import BlogPostListItem from '../common/BlogPostListItem';
import BlogPageLayout from './BlogPageLayout';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  postImage: {
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      paddingLeft: theme.spacing(0),
    },
    float: 'right',
    textAlign: 'right',
    maxWidth: '100%',
    paddingLeft: theme.spacing(4),
  },
  postHolder: {
    display: 'table',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  postHeader: {
    display: 'block',
  },
}));

const BlogCategoryPage = ({ match }) => {
  const classes = useStyles();
  const categorySlug = match.params['category'];
  let component = <Redirect to='/404' />;

  if (categorySlug in categories) {
    const category = categories[categorySlug];
    const pageTitle = category.title;

    component = (
      <BlogPageLayout>
        <Typography variant="h1" className={classes.pageTitle} gutterBottom>
          {pageTitle}
        </Typography>
        <hr />
        <Grid>
          {category.posts.map((postSlug) => {
            const post = posts[postSlug];
            return (
              <Grid item key={postSlug}>
                <BlogPostListItem
                  slug={postSlug}
                  title={post.title}
                  image={post.image}
                  desc={post.desc}
                  authorSlug={post.author}
                  date={post.date}
                />
              </Grid>
            );
          })}
        </Grid>
      </BlogPageLayout>
    );
  }
  return component;
};

export default BlogCategoryPage;
