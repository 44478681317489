import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import environment from '../../configs/environment';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.paper}>
      We use hiring companies' trademarks for descriptive purposes
      only and do not intend to imply their endorsement of this
      program. To access job listings, you must agree to our <a
      href={environment.talroo_terms_page} target="_blank"
      rel="noopener noreferrer">Terms & Conditions</a>, <a
      href={environment.talroo_privacy_page} target="_blank"
      rel="noopener noreferrer">Privacy Policy</a>, and <a
      href={environment.talroo_ccpa_privacy_page} target="_blank"
      rel="noopener noreferrer">CCPA Privacy Notice</a>.<br />
      <a
        href={environment.talroo_ccpa_dns_page}
        target="_blank"
        rel="noopener noreferrer"
      >
        Do Not Sell My Personal Information
      </a>.
    </Typography>
  );
};

export default Footer;
