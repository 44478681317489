import React from 'react';
import { Route } from 'react-router-dom';

import withQueryParameters from '../../hocs/withQueryParameters';
import JobsDisplay from '../JobsDisplay';
import ResultsPage from '../page_layouts/ResultsPage';

const ResultsRoute = ({ bypass, ...rest }) => {
  let component = (
    <ResultsPage>
      <JobsDisplay {...rest} />
    </ResultsPage>
  );

  return (
    <Route {...rest} render={() => component} />
  );
};

export default withQueryParameters(ResultsRoute);
