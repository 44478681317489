import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import CopyrightStatement from '../common/CopyrightStatement';
import MenuBar from '../common/MenuBar';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'left',
  },
}));

const BlogPageLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <MenuBar />
      <Container maxWidth="md" className={classes.container}>
        {children}
      </Container>
      <CopyrightStatement />
    </>
  );
};

export default BlogPageLayout;
