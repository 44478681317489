import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import * as PropTypes from 'prop-types';
import React, { useContext } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Disclosure from './common/Disclosure';

import PositionContext from './common/PositionContext';
import EmailForm from './EmailForm';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'left',
    paddingRight: theme.spacing(4),
  },
  dialogContent: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(12),
    },
  },
  dialogTitleHolder: {
    fontWeight: 'bold',
    display: 'block',
  },
  closeButtonHolder: {
    display: 'inline-block',
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
  },
  iconHolder: {
    paddingBottom: theme.spacing(2),
  },
  messageHolder: {
    paddingBottom: theme.spacing(2),
  },
}));

const Transition = React.forwardRef((props, ref) =>
  <Slide direction="up" ref={ref} {...props} />);

const EmailDialog = ({ title }) => {
  const classes = useStyles();
  const context = useContext(PositionContext);
  const { emailOpen: open } = context.state;

  const handleClose = () => {
    context.updateValue('emailOpen', false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={open}
      maxWidth="sm"
      onClose={() => handleClose()}
    >
      <DialogTitle id="dialog-title" className={classes.header}>
        <div className={classes.closeButtonHolder}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
            data-ga-type="Close_Dialog"
            data-ga-content={title}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogTitleHolder}>
          <Typography variant="h5">{title}</Typography>
        </div>
        <div className={classes.iconHolder}>
          <MailOutlineIcon style={{ fontSize: 80 }} />
        </div>
        <div className={classes.messageHolder}>
          <Typography variant="body1">
            Would you like to receive personalized job recommendations by email?
          </Typography>
        </div>
        <EmailForm submitCallback={handleClose} />
        <br />
        <Button
          onClick={() => handleClose()}
          size="small"
          data-ga-type="Close_Dialog_No_Thanks"
          data-ga-content={title}
        >
          No Thanks
        </Button>
        <Disclosure />
      </DialogContent>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  title: PropTypes.string.isRequired,
};

export default EmailDialog;
