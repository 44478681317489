import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  authorHolder: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  authorHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  authorImage: {
    display: 'inline-block',
    height: 80,
    margin: '0 auto 0',
    width: 80,
    borderRadius: 100,
  },
  authorLink: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
}));

const Author = ({ image, name, desc, slug }) => {
  const classes = useStyles();
  return <section className={classes.authorHolder}>

    <Link
      className={classes.authorLink}
      to={`/blog/author/${slug}`}
      data-ga-type="Blog_Click"
      data-ga-content={`/blog/author/${slug}`}
      rel="author"
    >
      <img
        alt=""
        src={image}
        height="160"
        width="160"
        className={classes.authorImage}
      />
      <Typography variant="h5" gutterBottom className={classes.authorHeader}>
        <span itemProp="name">{name}</span>
      </Typography>
    </Link>
    <Typography variant="body1" itemProp="description">
      {desc}
    </Typography>
  </section>;
};

export default Author;
