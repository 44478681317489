import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import api from '../apis/api';
import withQueryParameters from '../hocs/withQueryParameters';
import { buildResultsUrl } from '../utils/buildUrl';
import { isValidZipCode } from '../utils/locationUtils';
import { useDebounce } from '../utils/useDebounce';

const useStyles = makeStyles((theme) => ({
  recommendations: {
    display: 'inline-block',
    minHeight: 115,
    width: '100%',
    paddingTop: theme.spacing(1),
    minLength: 1000,
  },
  recommendationHolder: {
    color: theme.palette.info.dark,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textTransform: 'lowercase',
    textDecoration: 'none',
    justifyContent: 'left',
    textAlign: 'left',
  },
  loading: {
    textAlign: 'center',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const RelatedSearches = ({ history, company, loc }) => {
  const [recommendations, setRecommendations] = useState([]);
  const debouncedSearchTerm = useDebounce(loc, 700);
  const classes = useStyles();

  const handleClick = (recommendation) => {
    history.push(buildResultsUrl(
      recommendation,
      loc,
      0,
      { t1: 'related', bypass: 1 },
    ));
  };

  useEffect(
    () => {
      if (debouncedSearchTerm && isValidZipCode(debouncedSearchTerm)) {

        getRecommendations('jobs near me');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm],
  );

  const getRecommendations = (recommendation) => {
    api.getRecommended(recommendation, 10, loc,
    ).then((response) => {
      setRecommendations(response);
    }).catch(() => {
      api.getRecommended('jobs near me', 10).then((response) => {
        setRecommendations(response);
      });
    });
  };

  return (isValidZipCode(debouncedSearchTerm) ?
      (<div className={classes.recommendations}>
        <Typography variant="h6">
          Top Searches in Your Area
        </Typography>
        <div>
          {recommendations.length > 0
            ? recommendations.map((recommendation) => (
              <div key={recommendation}>
                <Button
                  fullWidth
                  className={classes.link}
                  title={recommendation}
                  onClick={() => handleClick(recommendation)}
                  data-ga-type="Related_Query_Click"
                  data-ga-content={recommendation}
                >
                  <div className={classes.recommendationHolder}>
                    <Chip label="View" className={classes.chip} />
                    <Typography variant="body1">
                      {recommendation}
                    </Typography>
                  </div>
                </Button>
              </div>
            ))
            : <CircularProgress className={classes.loading} />}
        </div>
      </div>)
      : null
  );
};

export default withQueryParameters(withRouter(RelatedSearches));
