import { makeStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { buildResultsUrl } from '../../../utils/buildUrl';

const useStyles = makeStyles((theme) => ({
  jobRoot: {
    position: 'relative',
    background: 'transparent',
    width: 'auto',
    height: '100%',
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
  },
  companyLogoHolder: {
    marginTop: theme.spacing(1),
    height: 100,
    width: 100,
    borderRadius: '50%',
    margin: 'auto',
    alignItems: 'center',
    overflow: 'hidden',
  },
  companyLogo: {
    height: 100,
    width: 100,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}));

const Company = ({ company, loc, logoUrl }) => {
  const classes = useStyles();

  return (
    <Link
      to={buildResultsUrl(company, loc, 0, { bypass: 1 })}
      className={classes.link}
    >
      <div className={`${classes.jobRoot}`}>
        <div className={classes.companyLogoHolder}>
          <div
            className={classes.companyLogo}
            title={`${company} Company Logo`}
            style={{ backgroundImage: `url(${logoUrl})` }}
          />
        </div>
      </div>
    </Link>
  );
};

Company.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  company: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
};

export default Company;
