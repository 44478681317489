import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import * as md5 from 'md5';
import React, { useContext, useState } from 'react';
import api from '../apis/api';
import { isValidEmail, loadPixelsAfterRegistration } from '../utils/commonUtils';

import PositionContext from './common/PositionContext';

const useStyles = makeStyles((theme) => ({
  formItem: {
    borderRadius: theme.spacing(0.5),
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  inputLabel: {
    color: theme.palette.common.white,
  },
  formBox: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  errorMessage: {
    color: theme.palette.common.white,
  },
}));

const EmailForm = ({ submitCallback }) => {
  const context = useContext(PositionContext);
  const { position, zipCode } = context.state;
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setErrorMessage('');
    const { target } = e;
    const { value } = target;
    setEmail(value);
  };

  const handleSubmit = () => {
    if (!email) {
      setErrorMessage('You must enter an email.');
    } else if (!isValidEmail(email)) {
      setErrorMessage('You must enter a valid email.');
    } else {
      api.subscribeContact(email, position, zipCode).then(() => {
      }).finally(() => {
        loadPixelsAfterRegistration();
      });
      Cookies.set('uid', md5(email));
      Cookies.set('email', email);
      context.updateValue('uid', md5(email));
      context.updateValue('email', email);
      submitCallback();
      const scroll = document.getElementById('JobHeader');
      if (scroll) {
        scroll.scrollIntoView({ block: 'end', inline: 'nearest' });
      }
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className={classes.formBox}>
      <form onSubmit={onFormSubmit}>
        <InputLabel htmlFor="zipCode">
          <Typography variant="h6" className={classes.inputLabel}>
            Confirm Your Email Address
          </Typography>
        </InputLabel>
        <TextField
          className={classes.formItem}
          autoFocus
          name="email"
          onChange={handleChange}
          value={email}
          variant="outlined"
          fullWidth
          size="small"
          color="secondary"
        />
        <div>
          <Typography variant="subtitle2" className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </div>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          data-ga-type="Email_Submit"
          data-ga-content="True"
          fullWidth
          type="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EmailForm;
