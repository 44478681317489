import axios from 'axios';
import environment from '../configs/environment';

const api = {
  getJobs: (
    apiKey,
    searchConfig,
    loc,
    qValue = '',
    start = 0,
    limit,
    minor_category,
  ) =>
    axios.get(
      `${environment.talroo_search_api}`,
      {
        params: {
          id: searchConfig.id || environment.default_ad_pub_id,
          pass: searchConfig.passcode || environment.default_ad_pub_pass,
          format: searchConfig.format || environment.default_api_format,
          link: searchConfig.link || environment.default_api_link,
          logo: searchConfig.logo || environment.default_api_logo,
          q: qValue,
          l: loc,
          match_top_category: 1,
          minor_category,
          start,
          limit,
        },
      },
    ).then((response) => response.data).catch((error) => {
      throw error;
    }),

  getCompaniesByLocation: (loc) =>
    axios.get(
      `${environment.j2c_web_api_endpoint}/topcompanies`,
      {
        headers: {
          'Authorization': `Bearer ${environment.j2c_web_api_bearer_token}`,
        },
        params: {
          location: loc,
        },
      },
    ).then((response) => response.data).catch((error) => {
      throw error;
    }),
};

export default api;
