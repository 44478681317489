import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post2 = () => {
  const classes = useStyles();
  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/remote-work-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/remote-work-tips`}
        >
          Remote Work Tips
        </Link>
      </Typography>
    </div>
    <article aria-label="7 Tips to Boost Productivity When Working From Home">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 466 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/08/workingfromhome.jpg"
          alt="how to work from home"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/alex`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/alex`}
            rel="author"
          >
            Alexandra Mankar
          </Link>
          &nbsp;/&nbsp;
          <time>January 26, 2018</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          7 Tips to Boost Productivity When Working From Home
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          Working from home was once reserved for the writers, artists, and
          entrepreneurs of the world. Today, it’s much more common, with
          approximately 3.9 million Americans working from home at least half
          the time. And, according to a study by the&nbsp;
          <a href="https://www.flexjobs.com/2017-State-of-Telecommuting-US/"
            /* eslint-disable-next-line react/jsx-no-target-blank */
             target="_blank" rel="nofollow noopener">
            2017 State of Telecommuting in the U.S. Employee Workforce Report,
          </a>
          &nbsp;that number of is growing.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Working remote can be the best: You save on gas and time commuting,
          but best of all, there’s never a line for the microwave. It’s so great
          that sometimes it can be hard to stay focused on your actual work.
          Which means your work days can grow longer as you try and play catch
          up on what didn’t get your full attention because you were watching
          videos on YouTube.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here are seven tips to make the most of your remote working
          experience:
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          1. Know your company’s remote work policies.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Inside and out, you should be aware of your company’s expectations of
          remote work. Do they want you to be available between 9 and 5? Or is
          your work more project-based and deadline driven? If you have the
          luxury of working for a company that allows you to work from home,
          even if it’s part of the time, you must adhere to company policy. One
          person abusing this perk could mean everyone losing the perk, and you
          don’t want that person to be you.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          2. Define a workspace.
        </Typography>
        <Typography variant="body1" gutterBottom>
          When you work from home, the line between your work and personal life
          may blur. You can play fetch with your dog while on a conference call,
          throw in a load of laundry in between emails, and wear pajamas all
          day. These are all major benefits of working from home, but it can
          become easy to let your home life distract you from your work
          responsibilities.
        </Typography>
        <Typography variant="body1" gutterBottom>
          By creating&nbsp;
          <a href="https://advice.j2c.com/clutter-free-workspace/">
            an organized space
          </a>&nbsp;that you only occupy during work hours, you can cut down
          distractions.
          I am sure there will be plenty of mornings that you work from bed; try
          to stick to your designated “office” space. By keeping work as
          separate from home life as possible, not only will you be able to
          focus on your work, but at the end of the day it will be easier to
          unwind on the couch, especially if you haven’t spent all day working
          there.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          3. Be available (and reasonable).
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you’re working on solo projects, this isn’t quite as important
          (although you should schedule regular check-ins and status reports
          with clients), but if you work as part of a team, make sure your team
          can reach you during normal working hours. Whether you use GChat,
          Slack, or another chat client to stay connected, keep it updated. If
          you run out to walk the dog, change your status to “on break,
          available at [insert time here].”
        </Typography>
        <Typography variant="body1" gutterBottom>
          Speaking of normal working hours, you may be at your most productive
          at midnight or 2 a.m. If you work as part of a team, respect their
          time and don’t send IMs late into the evening if the rest of your crew
          sticks to a more traditional schedule. If you’re a solo contractor,
          the same rule applies for clients. Sure, you can send them a draft at
          2 a.m., but no texts or calls unless it’s during <i>their </i>working
          hours.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          4. Play around with different organization techniques.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Planner addicts (like myself) struggle to grasp how anyone likes to
          use a digital calendar to plan their day. And digital adopters would
          be lost without calendar alerts on phones. The point is, everyone has
          a technique that works, and you need to find yours.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Some people map out ideas on post-it notes, and others use notebooks.
          A handwritten to-do list can do wonders, but so can a&nbsp;
          <a
            href="https://advice.j2c.com/5-apps-to-break-your-post-it-addiction/">
            task management app
          </a>. Take this opportunity to find what works best for you, test what
          works, scrap what doesn’t..
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          5. Eliminate distractions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Without co-workers watching your every move, it’s easy to get sucked
          into your favorite distractions. While it won’t be fun, your
          productivity will skyrocket by removing them from your workspace.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you love having the news on in the background, but every headline
          catches your attention, turn it off. And, you already know that
          putting your phone away is a good idea. Keeping tabs open for your
          personal Facebook, Twitter, or other non work-related social channels
          is also a distraction. If you can’t live without social media, set
          aside&nbsp;
          <a href="https://advice.j2c.com/overcome-your-to-do-list/">
            chunks of time
          </a>&nbsp;before and after work to read or post.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          6. Plan your weekdays.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Because you don’t have someone telling you where you must be at a
          certain time on Monday morning, you might tend to let your weekend
          errands slip. To be fair, the grocery store is much less crowded on
          weekday mornings, but if you save your home chores for the workweek
          you are going to fall behind.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Do your heavy cleaning, stock your fridge, and catch up on laundry
          over the weekend. These activities can take up a lot of your time and
          focus. Set a chore schedule outside of work hours and stick with it.
          You’ll feel saner during the week and will have no excuse not to get
          to work.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          7. Fill the void.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Working from home is a luxury many office workers jump at the chance
          to have, but it doesn’t mean that it’s always perfect. You might find
          yourself restless and this can cause you to find solace by scrolling
          through social media more often.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you find yourself going stir-crazy midday, head to a library or
          coffee shop. Take a walk. Do a 30-minute yoga class. If you can
          replace what working from home lacks then you will be happier and need
          less distractions to get through the workday.
        </Typography>
        <Typography variant="body1" gutterBottom>
          By using these tips, you can make an effort to keep your productivity
          on track, and even embrace kicking ass at work in the comfort of your
          own home.
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/remote-work-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/remote-work-tips`}
            rel="category tag"
          >
            Remote Work Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post2;
