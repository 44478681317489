import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post9 = () => {
  const classes = useStyles();
  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/workplace-issues`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/workplace-issues`}
        >
          Workplace Issues
        </Link>
      </Typography>
    </div>
    <article aria-label="How You Can Create Your Own Green Workspace">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 750, maxHeight: 500 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/01/greenwork2.jpg"
          alt="green workplace"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/brynhuntpalmer`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/brynhuntpalmer`}
            rel="author"
          >
            Bryn Huntpalmer
          </Link>
          &nbsp;/&nbsp;
          <time>January 25, 2018</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          How You Can Create Your Own Green Workspace
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          Let’s face it, a lot of offices don’t exactly have the freshest vibe.
          You thought that one coworker was toxic? Between the dust bunnies
          hiding behind your monitor, the fluorescent lights, and the ancient
          carpeting, the conditions in work sites leave a lot to be desired—both
          for your health, and the health of the planet.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unless you’re a Machiavellian mastermind, you probably don’t have much
          control over your coworkers—but one thing you <i>can </i>manipulate is
          your own workspace. In fact, there are tons of ways to improve the
          environmental footprint of your own little corner of the office
          planet. Check it out below!
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Kick the Plastic Spoon Habit
        </Typography>
        <Typography variant="body1" gutterBottom>
          If your company provides plasticware and paper plates, it can be easy
          enough to use them and toss them without thinking about it. According
          to the EPA, <a
          href="https://www.epa.gov/smm/advancing-sustainable-materials-management-facts-and-figures"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank" rel="nofollow">plastics comprise about 13 percent of
          our national trash</a>—and a lot of that is made up of single-use
          plastic products, like silverware. Stash a reusable spoon, fork, and
          knife in your desk drawer, and you’ll feel a lot less guilty about
          enjoying your morning yogurt.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Green Up the Air Quality in Your Space
        </Typography>
        <Typography variant="body1" gutterBottom>
          When you hear the words “air pollution,” you’re probably picturing a
          smoggy city skyline studded with smokestacks and skyscrapers. But
          indoor air quality is just as important, and in offices, pollutants
          like particulate matter, VOCs, radon, and carbon monoxide lower
          overall health, and&nbsp;
          <a
            href="https://www.ncbi.nlm.nih.gov/pubmed/15330777"
            /* eslint-disable-next-line react/jsx-no-target-blank */
            target="_blank"
            rel="nofollow"
          >may even affect your productivity and work performance</a>. Air
          quality experts usually recommend improving a
          building’s ventilation and filtration systems, but chances are you
          don’t have much say over that. However, that doesn’t mean you have to
          submit to unacceptable air quality standards. Several common
          houseplants actually double as natural air purifiers, and species like
          spider plants and ficuses do well in offices that receive little
          natural light. That’s one way to green up your desk—literally!
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Your Computer Deserves a Break, Too
        </Typography>
        <Typography variant="body1" gutterBottom>
          Headed out for a cup of coffee? Don’t forget to switch off your
          monitor! Same thing for meetings, lunch breaks, and any other
          commitments that call you away from your desk. In fact, <a
          href="https://energy.gov/energysaver/energy-efficient-computers-home-office-equipment-and-electronics"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank" rel="nofollow">the Department of Energy</a> says to
          save energy, you should turn off the monitor any time you plan on
          stepping away for longer than 20 minutes—and power down completely if
          you’ll be gone for two or more hours. If your office allows you to
          work from home occasionally, you have even more options. Purchase a
          laptop that’s rated for efficiency through the EPA’s Energy-Star
          program, and you’ll not only green up your work area, you could save
          up to $30 a year on electricity.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Nix the Paper Towels
        </Typography>
        <Typography variant="body1" gutterBottom>
          They may be helping you clean your hands, but paper towels are dirty
          business. According to the <a
          href="https://green.blogs.nytimes.com/2008/12/29/the-use-and-abuse-of-paper-towels/?_r=0"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank" rel="nofollow"><i>New York Times</i></a>, paper towels
          could make up to 40 percent of office waste. Even if your office has
          installed a hand dryer, the environmental benefits are only
          significant if the powers that be in your company opt for an
          energy-efficient model. To be sure of your hand towel footprint,
          simply shake your hands dry to avoid unnecessary waste. You could also
          bring your own towel from home and take it with you to the restroom.
          Not excited about carrying around a wet towel? A number of fast-drying
          eco-friendly paper towels are available for purchase, meant to be
          placed in your bag and used to eliminate paper towel waste. No one can
          question your commitment to the environment then!
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/workplace-issues`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/workplace-issues`}
            rel="category tag"
          >
            Workplace Issues
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post9;
