import { useEffect, useState } from 'react';

function getSize(isClient) {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
}

/**
 * From https://usehooks.com/useWindowSize/
 * @returns {{width: (number), height: (number)}}
 */
export const useWindowSize = () => {
  const isClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState(getSize(isClient));

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize(isClient));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]); // Empty array ensures that effect is only run on mount and
                  // unmount

  return windowSize;
};
