import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  copyright: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const CopyrightStatement = () => {
  const classes = useStyles();
  return (
    <div className={classes.copyright}>
      <Typography variant="subtitle2">
        {`Copyright © ${new Date().getFullYear()}`}
      </Typography>
    </div>
  );
};

export default CopyrightStatement;
