import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import notFoundImage from '../../assets/404.png';
import { siteName } from '../../assets/themes';

const useStyles = makeStyles((theme) => ({
  imageHolder: {
    width: '100%',
    height: 300,
    textAlign: 'center',
  },
  image: {
    height: 300,
    paddingTop: theme.spacing(1),
    flexGrow: 1,
  },
}));

const NotFoundImage = () => {
  const classes = useStyles();

  return (
    <div className={classes.imageHolder}>
      <img
        alt={`${siteName} Not Found`}
        className={classes.image}
        src={notFoundImage}
      />
    </div>
  );
};

export default NotFoundImage;
