import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as PropTypes from 'prop-types';
import React, {useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { useWindowSize } from '../../utils/useWindowSize';
import Job from './Job';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  sliderHolder: (props) => ({
    '& .slick-slide': {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 8,
      backgroundColor: theme.palette.common.white,
    },
  }),
  slickSlider: {
    display: 'block',
    maxWidth: 400,
    marginBottom: theme.spacing(8),
  },
  noThanksButton: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  slider: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
  },
  slickPrev: {
    [theme.breakpoints.up('sm')]: {
      left: -70,
    },
    [theme.breakpoints.down('xs')]: {
      left: 25,
    },
  },
  slickNext: {
    [theme.breakpoints.up('sm')]: {
      right: -70,
    },
    [theme.breakpoints.down('xs')]: {
      right: 25,
    },
  },
  slickButton: {
    boxShadow: 'none',
    zIndex: 5,
    lineHeight: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '50%',
      display: 'block',
      padding: 0,
      transform: 'translate(0, -50%)',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: -56,
      display: 'block',
      padding: 0,
    },
    cursor: 'pointer',
    fontSize: theme.typography.h3.fontSize,
    border: 'none',
    outline: 'none',
  },
}));

const NextArrow = ({ onClick, overrideOnClick }) => {
  const classes = useStyles();

  return (
    <Fab
      size="small"
      color="primary"
      aria-label="next slide"
      data-ga-type="RegPath_NextArrowButton"
      data-ga-content="Next"
      className={`${classes.slickButton} ${classes.slickNext}`}
      onClick={overrideOnClick ? overrideOnClick : onClick}
    >
      <NavigateNextIcon />
    </Fab>
  );
};

NextArrow.propTypes = {
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  onClick: () => {
  },
};

const PrevArrow = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab
      size="small"
      color="primary"
      aria-label="previous slide"
      data-ga-type="RegPath_PrevArrowButton"
      data-ga-content="Previous"
      className={`${classes.slickButton} ${classes.slickPrev}`}
      onClick={onClick}
    >
      <NavigateBeforeIcon />
    </Fab>
  );
};

PrevArrow.propTypes = {
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  onClick: () => {
  },
};

const JobsCarousel = ({ jobs, done}) => {
  const sliderRef = useRef();
  const size = useWindowSize();
  const [currentSlide, setCurrentSlide] = useState(0);
  const classes = useStyles({ width: size.width });
  let jobContent = null;

  if (jobs.length > 0) {
    jobContent = jobs.map((job, index) => {
      const jobData = {
        key: `job${job.id}`,
        title: job.title,
        id: job.id,
        price: job.price,
        description: job.description,
        logoUrl: job.logo_url,
        jobUrl: job.url,
        company: job.company,
        loc: job.city[0],
        dataGaType: 'RegPath_ApplyButton',
      };
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Job {...jobData} />;
    });
  }

  const callDone = () => {
    done && done();
  };

  const nextArrow = currentSlide === (jobs.length - 1)
    ? <NextArrow overrideOnClick={() => {
      callDone();
    }} />
    : <NextArrow />;

  // https://kenwheeler.github.io/slick/#settings
  const settings = {
    accessibility: true,
    className: 'center',
    centerMode: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    swipeToSlide: true,
    pauseOnHover: true,
    nextArrow: nextArrow,
    prevArrow: currentSlide === 0 ? null : <PrevArrow />,
    afterChange: current => setCurrentSlide(current),
  };

  return (
    <>
      <div className={classes.sliderHolder}>
        <Slider
          ref={(slider) => (sliderRef.current = slider)}
          {...settings}
          className={classes.slickSlider}
        >
          {jobContent}
        </Slider>
      </div>
      <Button
        aria-label="next slide"
        variant="outlined"
        data-ga-type="RegPath_NoThanksButton"
        data-ga-content="No Thanks"
        className={classes.noThanksButton}
        onClick={() => currentSlide === (jobs.length - 1)
          ? callDone()
          : sliderRef.current.slickNext()}
      >
        No Thanks
      </Button>
    </>
  );
};

JobsCarousel.propTypes = {
  jobs: PropTypes.array.isRequired,
  done: PropTypes.func.isRequired,
};

export default JobsCarousel;
