import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post5 = () => {
  const classes = useStyles();
  return <main className="content" id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/resume-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/resume-tips`}
        >
          Resume Tips
        </Link>
      </Typography>
    </div>
    <article
      aria-label="Resume Sections Guide: Which You Need &amp; How To Use Them">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 874, maxHeight: 525 }}
          src="https://advice.j2c.com/wp-content/uploads/2019/02/resume-sections-guide.jpg"
          alt=""
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/travis`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/travis`}
            rel="author"
          >
            Travis Brown
          </Link>
          &nbsp;/&nbsp;
          <time>March 18, 2020</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          Resume Sections Guide: Which You Need &amp; How To Use Them
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          Learning how to write a resume is easier
          than ever before. The difficult thing is knowing which resume sections
          you truly need.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Although the task itself is still a drag, the vast
          amount of resources available mean that there’s no excuse for not
          creating an effective resume.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If it’s time to start a new job
          search, you must first update your resume. Not only does it need to
          reflect your current skills and experience, but it must also catch the
          eye of those reviewing it.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your resume needs to be readable
          and easy to understand. This includes human readers and machine
          readers
          like bots, scanners, and ATS software. <a
          href="https://advice.j2c.com/get-your-resume-past-an-ats/">Getting
          your resume past an ATS</a> is no small feat!
        </Typography>
        <Typography variant="body1" gutterBottom>
          You must also
          strike the perfect balance between too much and not enough detail.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Dividing your resume into relevant sections is an age-old technique.
          It appeals to humans and computer apps alike.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We’ll show you the following to ensure your job search success:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>What resume sections you
            need</Typography></li>
          <li><Typography variant="body1" gutterBottom>What sections will
            improve your resume</Typography></li>
          <li><Typography variant="body1" gutterBottom>How to format each
            section</Typography></li>
        </ul>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Cover Letter
        </Typography>
        <figure className="wp-block-image">
          <img
            className={classes.img}
            style={{ maxWidth: 820, maxHeight: 481 }}
            src="https://advice.j2c.com/wp-content/uploads/2019/03/resume-cover-letter-example.png"
            alt="Resume cover letter example"
          />
          <figcaption>Resume cover letter example</figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          A cover letter is a short document sent alongside your resume.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Although it’s not quite part of your resume, it’s still
          important. Many employers now want a cover letter with all job
          applications.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If the employer doesn’t ask for a cover
          letter, it can still be smart to include one. A cover letter creates a
          strong first impression and helps you stand out from the crowd.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Beware though, a <a
          href="https://advice.j2c.com/terrible-cover-letter-ruining-perfect-resume/">bad
          cover letter can hold your resume back</a>.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          What Is a Cover Letter?
        </Typography>
        <Typography variant="body1" gutterBottom>
          A cover letter serves as a short introduction to the
          employer.

        </Typography>
        <Typography variant="body1" gutterBottom>
          If possible, address it to the hiring manager.
          This might take some snooping around online. This includes an online
          search or browsing social media, but it pays off big time.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Use the cover letter to introduce yourself. Briefly discuss your most
          relevant qualifications. Explain why you’re the best fit for the
          position.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          How to Write a Cover Letter?
        </Typography>
        <Typography variant="body1" gutterBottom>
          A cover
          letter starts with a greeting.

        </Typography>
        <Typography variant="body1" gutterBottom>
          As mentioned above, address
          the cover letter to the hiring manager if possible. Use all the tools
          at your disposal to find this person’s name:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>LinkedIn</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Social media</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Company’s
            website</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          If you're able to find them, you can address the document to them.
          Don't be creepy about it, though! If you're lucky, though, you might
          be able to <a
          href="https://advice.j2c.com/make-emotional-connection-cover-letter/">make
          an emotional connection with your cover letter</a>, which can really
          pay off.
        </Typography>
        <Typography variant="body1" gutterBottom>
          From here, introduce yourself by explaining who you
          are. Let them know what job you are applying for, and how you found
          out about it.

        </Typography>
        <Typography variant="body1" gutterBottom>
          If you heard about the position from someone
          within or related to the company, this is a good place to mention
          them. Make sure to clear it by this reference first, though.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The focus of the rest of the cover letter is to sell yourself. Think
          about it as a way to expand upon your resume. Explain relevant details
          and add additional information that doesn’t fit in other sections.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Remember to keep your cover letter short. One page is more than
          enough. Often three to five short paragraphs will do.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Read more: <a
          href="https://advice.j2c.com/cover-letter-will-make-resume-stand/">How
          to Write a Cover Letter That Makes a Resume Stand Out</a>
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Resume Summary
        </Typography>
        <figure className="wp-block-image">
          <img
            className={classes.img}
            style={{ maxWidth: 751, maxHeight: 326 }}
            src="https://advice.j2c.com/wp-content/uploads/2019/03/resume-summary-section.png"
            alt="Resume summary section example"
          />
          <figcaption>Resume summary section example</figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          A resume summary isn’t needed all the time. It is becoming more
          common these days, though.

        </Typography>
        <Typography variant="body1" gutterBottom>
          In fact, this resume section is
          quickly replacing the objective statement as the opening section of a
          resume. It usually goes after your contact information.

        </Typography>
        <Typography variant="body1" gutterBottom>
          As
          the name implies, the summary is a shortlist of your
          qualifications.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The whole of your resume includes your work
          experience and education. Though, the summary should highlight
          qualifications most relevant to the position.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          What Should I Include in a Summary?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Keep your resume summary to three or four
          short lines.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The goal of the summary is to give a brief
          overview of your career. So, quickly and clearly summarize your main
          qualifications.

        </Typography>
        <Typography variant="body1" gutterBottom>
          A short bullet point list (four to six
          bullets) is a concise way to get your point across.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Bullet
          points break down qualifications and stand out to hiring managers
          scanning many resumes.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Don’t just put
          qualifications/statements in your summary willy-nilly. The best resume
          has a summary that ties your experience and career goals neatly
          together.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Who Needs a Summary?
        </Typography>
        <Typography variant="body1" gutterBottom>
          A resume summary is
          most often used by more experienced pros.

        </Typography>
        <Typography variant="body1" gutterBottom>
          For those with
          extensive career experience, the summary acts as a way to condense. It
          helps break qualifications down to a few of the most important
          highlights.

        </Typography>
        <Typography variant="body1" gutterBottom>
          That said, anyone can use this resume section to
          great effect. This includes those seeking part-time or seasonal
          employment, and even work-from-home gigs.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Objective Statement
        </Typography>
        <Typography variant="body1" gutterBottom>
          An objective statement is the classic resume
          introduction.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Like the resume summary, the objective
          statement lists your employment goals. It also highlights your
          relevant skills and experience.

        </Typography>
        <Typography variant="body1" gutterBottom>
          An effective statement is
          very short – only one or two sentences at most.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Often, you’ll include either a summary or objective statement on your
          resume.
          There’s no reason to include both since they achieve much the same
          thing.

        </Typography>
        <Typography variant="body1" gutterBottom>
          That said, objective statements have fallen out of
          fashion as of late. They usually state the very obvious – that you
          want a certain job. Many experts agree they’re not usually needed.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          When to Use an Objective Statement?
        </Typography>
        <Typography variant="body1" gutterBottom>
          It’s your call to
          choose between an objective statement or resume summary (or,
          sometimes, a resume profile).

        </Typography>
        <Typography variant="body1" gutterBottom>
          The summary can include your
          employment goals as well as your most relevant skills and experience.
          It’s made the objective statement largely irrelevant.

        </Typography>
        <Typography variant="body1" gutterBottom>
          An
          objective statement can still be useful for times when emphasizing
          your goals is important.

        </Typography>
        <Typography variant="body1" gutterBottom>
          For example, part-time or contract
          jobs – like for remote work. These types of jobs often work well for
          an objective statement.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Because of the nature of the work,
          it pays off to explain what you hope to gain from it as well as how
          you can help the company accomplish its goals.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Resume Profile
        </Typography>
        <figure className="wp-block-image">
          <img
            className={classes.img}
            style={{ maxWidth: 863, maxHeight: 254 }}
            src="https://advice.j2c.com/wp-content/uploads/2019/03/resume-profile-section.png"
            alt="Resume profile section example"
          />
          <figcaption>Resume profile section example</figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Yet another option for the introduction to your resume is the resume
          profile.

        </Typography>
        <Typography variant="body1" gutterBottom>
          This resume section also shares much with the
          summary and objective statement sections. It does have a few key
          differences to be aware of.

        </Typography>
        <Typography variant="body1" gutterBottom>
          A resume profile is more like a
          resume summary than an objective statement.

        </Typography>
        <Typography variant="body1" gutterBottom>
          It consists of a
          brief summary of the following items relevant to the position:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Skills</Typography></li>
          <li><Typography variant="body1" gutterBottom>Work
            experience</Typography></li>
          <li><Typography variant="body1" gutterBottom>Education</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Other
            qualifications</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          A resume profile focuses more on career experience. Summaries focus
          more on your qualifications.

        </Typography>
        <Typography variant="body1" gutterBottom>
          However, for most job seekers,
          there’s little to no difference between the two. You can think of a
          profile and summary as much the same thing.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Work Experience
        </Typography>
        <figure className="wp-block-image">
          <img
            className={classes.img}
            style={{ maxWidth: 751, maxHeight: 326 }}
            src="https://advice.j2c.com/wp-content/uploads/2019/03/resume-work-experience-section.png"
            alt="Work experience section example"
          />
          <figcaption>Work experience section example</figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Okay – we’ve finally made it through the resume introduction!

        </Typography>
        <Typography variant="body1" gutterBottom>
          Now that you’ve made the decision between an objective statement and
          resume summary/profile, it’s time to detail your work experience.

        </Typography>
        <Typography variant="body1" gutterBottom>
          “Work Experience” and “Professional History” are the most popular
          titles. “Professional Experience,” “Employment History,” or a similar
          title also works well for this section.

        </Typography>
        <Typography variant="body1" gutterBottom>
          This is the section
          used to describe any paid work experience – other work experience,
          such as volunteer work, you can detail later on.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Everyone should have this section on their resume, no matter the
          position.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          What to Include in the Work Experience Section
        </Typography>
        <Typography variant="body1" gutterBottom>
          The work
          experience section of your resume includes your previous employment
          experience.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Make sure to include the following or any other
          relevant information:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Name of each
            company</Typography></li>
          <li><Typography variant="body1" gutterBottom>Dates you worked
            there</Typography></li>
          <li><Typography variant="body1" gutterBottom>Your
            position</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          There are a ton of different ways to format this section.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Remember, that both human readers and robot resume readers prefer
          easy to read lists.

        </Typography>
        <Typography variant="body1" gutterBottom>
          One option is to use bullet lists.
          First, list the main information about a job:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Company</Typography></li>
          <li><Typography variant="body1" gutterBottom>Dates
            employed</Typography></li>
          <li><Typography variant="body1" gutterBottom>Position</Typography>
          </li>
        </ul>
        <Typography variant="body1" gutterBottom>
          You can follow this up with a bullet list of your
          responsibilities.

        </Typography>
        <Typography variant="body1" gutterBottom>
          But remember not to go overboard.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The work experience section is the place to describe your history
          in-depth. Keep it short and sweet, though.

        </Typography>
        <Typography variant="body1" gutterBottom>
          In the same vein,
          you don’t have to list all the jobs you’ve ever had.

        </Typography>
        <Typography variant="body1" gutterBottom>
          If you
          have an extensive career history, only list relevant positions on your
          resume. Another option is to only go back only 10 or 15 years at the
          most.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Only list the work experience relevant to the
          position. If you’re applying for a mid-level position, you don’t need
          to include entry-level jobs on your resume.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Those with
          little job history can decide to include each position to fill their
          resume out. That includes paid internships, summer jobs, contract
          jobs, and temp jobs.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          What to Do If You Have a Gap in Your Employment
        </Typography>
        <Typography variant="body1" gutterBottom>
          A lot of people worry about what to do if they have
          a gap in their employment.

        </Typography>
        <Typography variant="body1" gutterBottom>
          There’s an endless number of
          reasons for an employment gap – from raising a young child to taking
          care of a sick relative.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Keep in mind that an employment gap
          should only be a big concern if you’re applying for your first
          position since your career break.

        </Typography>
        <Typography variant="body1" gutterBottom>
          If you've already returned
          to the workforce, there's usually no reason to call attention to a
          gap. The employer will more than likely overlook it anyway.

        </Typography>
        <Typography variant="body1" gutterBottom>
          As for applying to your first position since an employment gap, you
          have two main options:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Explain the gap
            upfront</Typography></li>
          <li><Typography variant="body1" gutterBottom>Make any gaps less
            obvious</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          Taking attention away from an employment gap is quite easy by
          changing dates to include only years (instead of month/year). You can
          also omit one or two irrelevant or semi-relevant jobs on your resume.
          Another option is filling in the gaps with freelance work or part-time
          gigs.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The other option is to bring up the employment gap
          right away.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Often, this works well for stay-at-home parents
          returning to the workforce. This is especially true for those
          interested in part-time, contract, or remote positions.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Explain in your cover letter, objective statement or resume summary
          the reason for the employment gap.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Whether you choose to
          explain the gap or make it less obvious, you should always be upfront
          and honest with the employer if you get an interview.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Here are a
          few other tips on <a
          href="https://advice.j2c.com/explain-gap-employment-history/">how to
          explain gaps in employment</a>.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Skills and Abilities
        </Typography>
        <Typography variant="body1" gutterBottom>
          The work experience section of a resume usually follows the intro
          sections.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Those that want to highlight skills and abilities
          might choose to include this section first.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Others, choose
          to put it after the work experience section. Others yet might include
          it after the education section. Some don’t include it at all.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The method you choose should depend on the special skills and
          abilities you have – and how strongly they apply to the position.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          What Is the Skills and Abilities Resume Section?
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can
          use the skills and abilities section to highlight proven skills you’ve
          learned during your time working and studying.

        </Typography>
        <Typography variant="body1" gutterBottom>
          It’s most
          common to highlight other skills that your experience section didn’t
          cover.

        </Typography>
        <Typography variant="body1" gutterBottom>Examples include:</Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Foreign language
            skills</Typography></li>
          <li><Typography variant="body1" gutterBottom>Skills with certain
            software or hardware</Typography></li>
          <li><Typography variant="body1" gutterBottom>Public speaking
            skills</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>Refrain from listing basic
          skills like “ability to work under
          pressure” on your resume. Favor detailing work experience that proves
          that you have these skills.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Whatever you include in this
          section, make sure that it’s in some way relevant to the position or
          industry in question.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Education Section
        </Typography>
        <figure className="wp-block-image">
          <img
            className={classes.img}
            style={{ maxWidth: 863, maxHeight: 254 }}
            src="https://advice.j2c.com/wp-content/uploads/2019/03/resume-education-section.png"
            alt="Resume education section example"
          />
          <figcaption>Resume education section example</figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Education is usually the second (or third, if you include the
          objective/summary) section on a resume.

        </Typography>
        <Typography variant="body1" gutterBottom>
          You can include it
          directly after the work experience section. Another option is to use
          the skills and experience section between work experience and
          education.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Either way, the education section is the place to
          outline your academic achievements. This includes colleges you’ve
          attended and any degrees you’ve received.

        </Typography>
        <Typography variant="body1" gutterBottom>
          This is also the
          place to include any relevant awards or honors.

        </Typography>
        <Typography variant="body1" gutterBottom>
          There’s a
          little debate as far as including the date of graduation goes.
          Although doing so has been normal for many years, some experts advise
          against it.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The reason is that the date you graduated can
          “date you” in some cases.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The only time when you need a date
          is if you’re still a student or have recently graduated.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Current students and graduates might also consider including their
          GPA (unless its low). This is not vital – and, in fact, not encouraged
          – for most other people.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Courses and Certifications
        </Typography>
        <Typography variant="body1" gutterBottom>
          Most job seekers can work relevant courses and certifications into
          other resume sections.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Sometimes, however, creating a
          separate section is in your favor.

        </Typography>
        <Typography variant="body1" gutterBottom>This is the case
          if:</Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>The job is very
            specialized</Typography></li>
          <li><Typography variant="body1" gutterBottom>You have specific
            industry certifications</Typography></li>
          <li><Typography variant="body1" gutterBottom>You’ve completed relevant
            coursework</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          Examples of what you could list here include other degrees, technical
          courses, and industry certs.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Professional licenses are even
          more important to list on your resume.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Many people struggle
          to find another relevant place to include licenses. Including them in
          this section can take some of the guesswork out of it.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Listing your licenses, courses, and certifications can help your
          resume stand out.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Like always, keep this resume section
          short and sweet. Don’t include anything that’s not needed.

        </Typography>
        <Typography variant="body1" gutterBottom>
          For example, only list courses, certifications, and licenses relevant
          to the position.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Honors &amp; Awards
        </Typography>
        <Typography variant="body1" gutterBottom>
          This is an optional section that not many people need to include on
          their resume.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Most people can include honors or awards under a different
          section of their resume. “Work experience” or “Education” are two
          options.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The exception is if you have important honors or
          awards you feel are useful to highlight.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Only include
          prestigious awards or those that have specific relevance to the
          position you're applying for here.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Remember to detail the
          scope of the award. Explain what the award is about, and why you
          received it, rather than just listing the name of the award.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Volunteer Work
        </Typography>
        <Typography variant="body1" gutterBottom>
          This section is an excellent place to discuss
          volunteer experience on your resume. People often use names like
          “Volunteer Work” or “Community Engagement” for this section.

        </Typography>
        <Typography variant="body1" gutterBottom>
          It’s not a required resume section, but it is good for some
          people.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Only add this section to your resume if the skills
          and experience are relevant to your job hunt.

        </Typography>
        <Typography variant="body1" gutterBottom>
          It’s an
          especially good idea to include a volunteer work section if you
          developed any skills here that you’d like to highlight.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Current students and graduates can also benefit from adding volunteer
          work to a resume. Because they might lack much work experience, every
          little bit helps.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Finally, a volunteer work section can help
          explain gaps in employment.

        </Typography>
        <Typography variant="body1" gutterBottom>
          You can use this section to show
          you've continued to develop skills during a career break. This is
          especially useful for stay-at-home parents returning to the workforce.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Activities &amp; Interests
        </Typography>
        <Typography variant="body1" gutterBottom>
          Now that we’re getting towards
          the end of your resume. Here's an important thing to note. <strong>Keep
          your resume short and concise.</strong>

        </Typography>
        <Typography variant="body1" gutterBottom>
          As mentioned above, one – or sometimes two – pages is more than
          enough.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Although there is a time and place to include your activities and
          interests on a resume, it’s often the least important section.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Unless your activities and interests are relevant to the job, there's
          no reason to include them.

        </Typography>
        <Typography variant="body1" gutterBottom>
          In fact, the company likely
          doesn't care about your outside interests, activities, and
          hobbies.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The exception is when they are professionally
          relevant. Another exception is when the knowledge gained from the
          hobby could help you in the position.

        </Typography>
        <Typography variant="body1" gutterBottom>
          An example is if you
          apply to a company involved in athletics. Experience in a sport or
          athletic activity could be relevant.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Running a personal blog
          is another example. A marketing company might like to hear about your
          marketing and social media knowledge.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Just don’t include a
          laundry list of hobbies. And, remember that your interests don’t
          always translate to actual skills.

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          References Section
        </Typography>
        <Typography variant="body1" gutterBottom>
          A list of professional references has a lot of benefits.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Though, it’s not necessary to include them on or with your resume,
          unless the job posting requests them.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Although it used to be
          standard practice, doing so has fallen out of practice. Of course, you
          can include references if you'd like, but note that it’s not required.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Many experts agree that it's best to leave them off your resume
          unless asked for them. This is especially true if the job listing asks
          that you don’t include references.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Like any other resume
          section, there are some exceptions.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Including a short
          reference section at the end of your resume, only if space allows,
          works well for some positions.

        </Typography>
        <Typography variant="body1" gutterBottom>
          If you do decide to include
          references, or the job posting requests them, make sure that this
          section is short and sweet, like the rest of your resume. All you need
          is the name, job title, company, and contact information for each
          reference.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Another option is to attach a separate references
          document alongside your cover letter and resume.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Remember to
          contact your references first. You don't want them caught off guard if
          they do receive a follow-up call. (you want to make sure they'll give
          you a positive reference).

        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Final Thoughts on Resume Sections
        </Typography>
        <Typography variant="body1" gutterBottom>
          Remember that not every resume must include every one
          of these sections.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Other than these, the other parts of a resume are optional:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Career
            summary</Typography></li>
          <li><Typography variant="body1" gutterBottom>Work
            experience</Typography></li>
          <li><Typography variant="body1" gutterBottom>Education</Typography>
          </li>
          <li><Typography variant="body1" gutterBottom>Contact
            information</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          You should tailor them to each specific job application.

        </Typography>
        <Typography variant="body1" gutterBottom>
          The same goes for the order of your resume sections. Although our
          order of sections here works well, it's only a template. Mix the
          sections around as you see fit for the position in question.

        </Typography>
        <Typography variant="body1" gutterBottom>
          Finally, have someone check over your resume. Even if it’s a friend,
          it helps catch spelling, grammar, and other errors.

        </Typography>
        <Typography variant="body1" gutterBottom>
          A well-written resume is key to being a top contender for jobs.
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/resume-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/resume-tips`}
            rel="category tag"
          >
            Resume Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post5;
