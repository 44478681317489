import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import environment from '../configs/environment';
import { buildResultsUrl } from '../utils/buildUrl';
import PositionContext from './common/PositionContext';

const useStyles = makeStyles((theme) => ({
  paginationHolder: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 450,
    height: 50,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  backButton: {
    float: 'left',
    fontWeight: 'bold',
    height: 50,
    width: 150,
  },
  forwardButton: {
    float: 'right',
    fontWeight: 'bold',
    height: 50,
    width: 150,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const PaginationButtons = ({ history }) => {
  const context = useContext(PositionContext);
  const classes = useStyles();
  const { start } = context.state;

  const handleNext = () => {
    handleClick(start + environment.number_of_jobs);
  };

  const handlePrevious = () => {
    const newStart = start - environment.number_of_jobs;
    handleClick(newStart >= 0 ? newStart : 0);
  };

  const handleClick = (newStart) => {
    let { position, zipCode } = context.state;
    context.updateValue('companyJobs', []);
    context.updateValue('start', newStart);
    history.push(buildResultsUrl(position, zipCode, newStart));
    const scroll = document.getElementById('JobHeader');
    scroll.scrollIntoView({ block: 'end', inline: 'nearest' });
  };

  const prevButton = start !== 0
    ? <Button
      color="primary"
      variant="outlined"
      className={classes.backButton}
      onClick={handlePrevious}
    >
      Back
    </Button>
    : null;

  const nextButton = start < 100
    ? <Button
      color="primary"
      variant="outlined"
      className={classes.forwardButton}
      onClick={handleNext}
    >
      More Jobs
    </Button>
    : null;

  return (
    <div className={classes.paginationHolder}>
      {prevButton}
      {start === 0 ?
        <div className={classes.centered}>
          {nextButton}
        </div>
        : nextButton
      }
    </div>
  );
};

export default withRouter(PaginationButtons);
