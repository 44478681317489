import axios from 'axios';
import React, { useEffect, useState } from 'react';

import recommendApis from '../apis/recommendApis';

export const withJobs = (WrappedComponent) => (props) => {
  const [jobs, setJobs] = useState([]);
  const { ad_props = {} } = props;
  const { search_type } = ad_props;

  useEffect(
    () => {
      getJobs(search_type, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search_type],
  );

  const getJobs = (searchType, searchValues) => {
    const {
      api_key,
      loc,
      start,
    } = searchValues;

    const request1 = recommendApis.getJobs(
      api_key,
      {
        id: 6470,
        passcode: '9cNXQ45eB6wQ4Khe',
      },
      loc,
      '',
      start,
      1,
      '660050|450010|450080',
    );
    const request2 = recommendApis.getJobs(
      api_key,
      {
        id: 6471,
        passcode: 'zc5SGtAmOrnyYLgB',
      },
      loc,
      '',
      start,
      1,
      '550030|540050|540020|540010|550010',
    );
    const request3 = recommendApis.getJobs(
      api_key,
      {
        id: 6472,
        passcode: 'ftU30Z0h1aXgciyB',
      },
      loc,
      '',
      start,
      1,
      '630080',
    );
    const request4 = recommendApis.getJobs(
      api_key,
      {
        id: 6473,
        passcode: 'KAprIJ5d9wEKiRSo',
      },
      loc,
      '',
      start,
      1,
      '20060|20030|20010|610030|130020|130040',
    );

    axios.all([request1, request2, request3, request4]).then(
      axios.spread((...responses) => {
        const allResponses = responses[0].jobs.concat(
          responses[1].jobs,
          responses[2].jobs,
          responses[3].jobs,
        );
        setJobs(allResponses.slice(0, 3));
      }),
    ).catch(
      errors => {
        // react on errors.
        console.error(errors);
      },
    );
  };

  return <WrappedComponent jobs={jobs} {...props} getJobs={getJobs} />;
};
