import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { isValidZipCode } from '../utils/locationUtils';

import PositionContext from './common/PositionContext';
import RelatedSearches from './RelatedSearches';

const useStyles = makeStyles((theme) => ({
  formItem: {
    borderRadius: theme.spacing(0.5),
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  inputLabel: {
    '@media (max-width: 359px)': {
      fontSize: '1rem',
    },
    color: theme.palette.common.white,
  },
  formBox: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  errorMessage: {
    color: theme.palette.common.white,
  },
}));

const ZipCodeForm = () => {
  const context = useContext(PositionContext);
  const classes = useStyles();
  const { googleLocation } = context.state;
  const [zipCode, setZipCode] = useState(googleLocation);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setErrorMessage('');
    const { target } = e;
    const { value } = target;
    setZipCode(value);
  };

  const handleSubmit = () => {
    if (!zipCode) {
      setErrorMessage('You must enter a zip code.');
    } else if (!isValidZipCode(zipCode)) {
      setErrorMessage('You must enter a valid zip code.');
    } else {
      Cookies.set('zip', zipCode);
      context.updateValue('zipSubmit', true);
      context.updateValue('zipCode', zipCode);
    }

    if (context.state.position) {
      context.updateValue('submit', true);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div>
      <form onSubmit={onFormSubmit}>
        <div className={classes.formBox}>
          <InputLabel htmlFor="zipCode">
            <Typography variant="h6" className={classes.inputLabel}>
              Confirm Zip Code to View jobs
            </Typography>
          </InputLabel>
          <TextField
            className={classes.formItem}
            id="zipCode"
            name="zipCode"
            defaultValue={googleLocation}
            autoFocus
            onChange={handleChange}
            variant="outlined"
            fullWidth
            size="small"
            color="secondary"
          />
          <div>
            <Typography variant="subtitle2" className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          </div>
          <Button
            className={classes.button}
            fullWidth
            color="primary"
            variant="contained"
            data-ga-type="Zip_Submit"
            data-ga-content={zipCode}
            type="submit"
          >
            Get Started
          </Button>
        </div>
      </form>
      <RelatedSearches loc={zipCode} />
    </div>
  );
};

export default ZipCodeForm;
