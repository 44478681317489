import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import withQueryParameters from '../../hocs/withQueryParameters';
import PositionContext from '../common/PositionContext';
import JobsDisplay from '../JobsDisplay';
import ResultsPage from '../page_layouts/ResultsPage';
import Profile2Dialog from '../profile2/Profile2Dialog';

const Profile2Route = ({ q, l, ...rest }) => {
  const context = useContext(PositionContext);
  const { position, zipCode } = context.state;

  const component = (
    <>
      <ResultsPage modalOption="profile2Open">
        <JobsDisplay q={q ? q : position} l={l ? l : zipCode} {...rest} />
      </ResultsPage>
      <Profile2Dialog
        openFlagName="profile2Open"
        title=""
      />
    </>
  );

  return (
    <Route {...rest} render={() => component} />
  );
};

export default withQueryParameters(Profile2Route);
