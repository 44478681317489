import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post10 = () => {
  const classes = useStyles();
  return <main className="content" id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/career-advice`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/career-advice`}
        >
          Career Advice
        </Link>
      </Typography>
    </div>
    <article
      aria-label="5 Important Career Lessons Smart People Tend to Forget"
    >
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 466 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/07/Career-Lessons.jpg"
          alt="career lessons"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/evawislow`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/evawislow`}
            rel="author"
          >
            Eva Wislow
          </Link>
          &nbsp;/&nbsp;
          <time>January 26, 2018</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          5 Important Career Lessons Smart People Tend to Forget
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          In today’s society, money is definitely a necessity. They say that
          money doesn’t bring happiness, yet this very statement is
          questionable. We want to have fun, we want to experience new things,
          and we want to diversify our lives. The condition for all these?
          <b>Money</b>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          That is the reason why most individuals are explicitly focusing on
          developing a successful career that can assure a brighter future. If
          you’re right at the start of your journey or you are already working
          to accomplish your goals, paying attention to other professionals’
          mistakes and failures can boost your professional development big
          time.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Without further ado, let’s talk about some important career lessons
          that many individuals tend to forget throughout their road towards
          success. Pay close attention and don’t let this powerful knowledge
          slip through your fingers. Apply it and see what happens!
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          1) Work Should Be Guided by Love
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you want to be happy, you should never base your career on
          materialistic principles. <a
          href="https://www.forbes.com/sites/maynardwebb/2015/09/22/how-to-find-passion-in-your-work/"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank"
          rel="nofollow noopener">
          Passion
        </a>&nbsp;is the key word here, and passion often means love. A general
          state of love towards the activity you’re performing each day will not
          only make you a happier person, but also a more proclaimed
          professional.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Passion is visible in everything. We can clearly differentiate someone
          who works because&nbsp;<b>he has to </b>and someone who works
          because <b>he wants to</b>. Make sure that you do what you love, and
          you’ll never work again. Instead, you’ll play with your passion each
          day!
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          2) Your Mindset is More Important than Everything Else
        </Typography>
        <Typography variant="body1" gutterBottom>
          Want to be successful with your career? Well, you need to work hard on
          yourself. The mindset you have right now is basically shifting your
          existence. The beliefs, the attitudes, the thought cycles that go on
          and on in your head—they are all significant factors that contribute
          to both your personal and professional life.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In order to cultivate a smooth career full of accomplishments, pay
          attention to your “inner self” as frequently as possible. Do
          introspections, read, meet new people, accept new perspectives, and
          continuously develop your mindset to the better!
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          3) Be Patient, Always!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Patience is a rare trait nowadays. Everyone’s trying to have success
          overnight, yet that’s why most people fail miserably with their
          rushing attempts. However, the truth is that overnight success is just
          a myth. You just can’t swallow any magic pill to accomplish great
          things. It’s all about consistent work and patience.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you <a
          href="http://www.dailygood.org/2010/07/06/five-practices-for-cultivating-patience/"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank"
          rel="nofollow noopener"
        >
          cultivate patience
        </a>, everything will fall into place. You’ll no longer be disappointed
          because today wasn’t the day, you won’t feel frustrated when other
          people around you succeed, and you’ll be a much more stable person.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Remember: success comes with patience. Most people give up their
          dreams because it’s too hard or it takes too long. If you have
          patience, these problems shouldn’t step in your way.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          4) Failure is Only Feedback
        </Typography>
        <Typography variant="body1" gutterBottom>
          Are you afraid of failure? Most people are! The human brain is&nbsp;
          <a
            href="http://brainrules.blogspot.ro/2010/12/business-of-pleasure-and-pain.html"
            /* eslint-disable-next-line react/jsx-no-target-blank */
            target="_blank"
            rel="nofollow noopener"
          >
            wired in such a way
          </a>&nbsp;that whenever there’s a possibility to feel mental or
          physical pain, it immediately looks for ways to avoid that pain. The
          fear of failure is so common among professionals. A lot of individuals
          give up right before they meet the success they’ve been looking for.
          In order to eliminate your fear of failure, change what failure means
          to you.
        </Typography>
        <Typography variant="body1" gutterBottom>
          I tend to perceive it as&nbsp;<b>feedback</b>. When you do something,
          there’s always an outcome. Inappropriate thoughts, actions, and
          behaviors lead to unsatisfying results. In order to change your
          results, watch the <b>feedback </b>(failure) and <b>change what you do
          next</b>.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          5) Never Stop Learning
        </Typography>
        <Typography variant="body1" gutterBottom>
          Successful people never give up on themselves. For them, learning new
          things throughout life is an essential part of their being. Knowledge
          is power. However, if we cease to improve our knowledge, we’ll become
          limited to what we know.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Steve Larsen, co-founder of <a
          href="https://www.resumesplanet.com/"
          /* eslint-disable-next-line react/jsx-no-target-blank */
          target="_blank"
          rel="nofollow noopener">Resumes Planet</a>, shared his opinion on the
          matter:
        </Typography>
        <Typography variant="body1" gutterBottom>
          “In order to be exceptional, you need to keep learning new things. How
          do you do that? Well, you can read books, consume digital information
          (e-books, articles), interact with other professionals, listen to
          other people (actually listen), and experience as many things as
          possible. Never stop learning, and you’ll never stop progressing!”
        </Typography>
        <Typography variant="body1" gutterBottom>
          Every career can have different turns. Every person’s journey is
          different, so never worry about what other professionals do. You can
          use their accomplishments to motivate yourself, but besides that, you
          need to focus on your own career’s road. Improve your mindset along
          the way and you’ll be guaranteed to gain fresh perspectives and
          opportunities!
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/career-advice`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/career-advice`}
            rel="category tag"
          >
            Career Advice
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post10;
