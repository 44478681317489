import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import BasicPageLayout from '../page_layouts/BasicPageLayout';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  buttonHolder: {
    overflow: 'hidden',
    textTransform: 'none',
    borderRadius: theme.spacing(4),
    padding: 0,
    color: theme.palette.text.primary,
    display: 'flex',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
  buttonText: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
  },
}));

const RegularButton = ({
  option,
  dataGaType,
  handleSubmit,
}) => {
  const classes = useStyles();
  return (
    <Button
      fullWidth
      className={classes.buttonHolder}
      onClick={() => handleSubmit()}
      data-ga-type={dataGaType}
      data-ga-content={option}
    >
      <Typography variant="body1" className={classes.buttonText}>
        {option}
      </Typography>
    </Button>
  );
};

const Profile3Form = ({
  dataGaType,
  icon,
  title,
  options,
  submitCallback,
  children,
}) => {
  const classes = useStyles();

  const handleSubmit = () => {
    submitCallback();
  };

  return (
    <BasicPageLayout>
      <div>
        {icon}
      </div>
      <Typography variant="h4" className={classes.header}>
        {title}
      </Typography>
      {options.map((option) => <RegularButton
        key={option}
        dataGaType={dataGaType}
        option={option}
        handleSubmit={handleSubmit}
      />)}
      <br />
      {children}
    </BasicPageLayout>
  );
};

export default Profile3Form;
