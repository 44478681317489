import { useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet/es/Helmet';

import api from '../apis/api';
import environment from '../configs/environment';
import { cityStateToZipCode, isValidZipCode } from '../utils/locationUtils';
import PositionContext from './common/PositionContext';
import CompanyJobs from './CompanyJobs';
import JobContainer from './JobContainer';
import JobsDialog from './JobsDialog';
import MobileSearchDialog from './MobileSearchDialog';
import MobileSearchForm from './MobileSearchForm';
import PaginationButtons from './PaginationButtons';
import Recommendations from './Recommendations';
import SearchForm from './SearchForm';

const useStyles = makeStyles((theme) => ({
  numberOfJobs: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  circular: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
  },
}));

const JobsDisplayDefault = ({ q, l, start, t1 }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const context = useContext(PositionContext);
  const classes = useStyles();
  const [jobTotal, setJobTotal] = useState('');
  const {
    jobs,
    uidJobs,
    relatedJobs,
    position,
    zipCode,
    uid,
  } = context.state;

  const handleJob = (id) => {
    api.getRelated(id, position, zipCode).then((response) => {
      context.updateValue('relatedJobs', response);
    });

    context.updateValue('relatedOpen', true);
  };

  const handleJobs = (newJobs) => {
    context.updateValue('jobs', newJobs);

    if (!zipCode) {
      for (const job of newJobs) {
        if ('city' in job && job['city'][0]) {
          const jobLoc = job['city'][0];
          const jobLocPieces = jobLoc.split(',');
          const jobZipCode = cityStateToZipCode(
            `${jobLocPieces[0]}, ${jobLocPieces[1]}`,
          );

          if (isValidZipCode(jobZipCode[0])) {
            Cookies.set('zip', jobZipCode[0]);
            context.updateValue('zipCode', jobZipCode[0]);
            break;
          }
        }
      }
    }
  };

  useEffect(
    () => {
      getJobs(q, l, uid, environment.number_of_jobs, start, t1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [q, l, start, uid, t1],
  );

  const getJobs = (
    qValue,
    lValue,
    uidValue,
    limitValue,
    startValue,
    t1Value,
  ) => {
    api.getJobs(
      qValue,
      lValue,
      uidValue,
      limitValue,
      startValue,
      t1Value,
    ).then((response) => {
      if (response.jobs.length > 0) {
        handleJobs(response.jobs);
        setJobTotal(response.total);
      } else {
        api.getJobs(
          '',
          lValue,
          uidValue,
          limitValue,
          startValue,
        ).then((response) => {
          handleJobs(response.jobs);
        });
      }
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const searchForm = <SearchForm
    getNewJobs={getJobs}
    keyword={q}
    loc={l}
    onSubmit={handleClose}
  />;

  return (
    <>
      <div id="container">
        <Helmet>
          <link rel="manifest" href="/manifest.json" />
          <script
            src={`https://api.pushnami.com/scripts/v1/push/${environment.pushnami_id}`}
          >
          </script>
        </Helmet>
        <MobileSearchDialog open={open} onClose={handleClose}>
          {searchForm}
          <Button size="small" onClick={handleClose}>Close</Button>
        </MobileSearchDialog>
        {matches ? searchForm : (<MobileSearchForm
          keyword={q}
          loc={l}
          onClick={handleClickOpen}
        />)}
        <CompanyJobs handleJob={handleJob} />
        <div id="JobHeader"></div>
        <Typography variant="h6" className={classes.numberOfJobs}>
          {jobTotal} {q ? q : position} Jobs {l ? `in ${l}` : (zipCode
          ? `in ${zipCode}`
          : 'Near You')}
        </Typography>
        <div>
          {jobs.length > 0
            ? jobs.map((job) =>
              <JobContainer
                key={`${job.title}${job.id}`}
                job={job}
                handleJob={handleJob}
              />)
            : <div className={classes.circular}>
              <CircularProgress />
            </div>
          }
        </div>
        <PaginationButtons />
        <Recommendations />
      </div>
      <JobsDialog
        handleJob={handleJob}
        openFlagName="relatedOpen"
        title="Jobs You Might Be Interested In"
        jobs={relatedJobs}
      />
      <JobsDialog
        handleJob={handleJob}
        openFlagName="uidOpen"
        title="Jobs Picked For You"
        jobs={uidJobs}
      />
    </>
  );
};

export default JobsDisplayDefault;
