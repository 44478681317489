import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import authors from '../blog/Authors';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  postImage: {
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      paddingLeft: theme.spacing(0),
    },
    float: 'right',
    textAlign: 'right',
    maxWidth: '100%',
    paddingLeft: theme.spacing(4),
  },
  postHolder: {
    display: 'table',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  postHeader: {
    display: 'block',
  },
}));

const BlogPostListItem = ({ slug, image, title, authorSlug, date, desc }) => {
  const classes = useStyles();

  return (
    <article
      className={classes.postHolder}
      aria-label="At Work With Lisa Cummings, Leader of Strengths"
    >
      <Link
        className={classes.postImage}
        to={`/blog/post/${slug}`}
        data-ga-type="Blog_Click"
        data-ga-content={`/blog/post/${slug}`}
        aria-hidden="true"
        tabIndex="-1"
      >
        <img
          width="300"
          height="200"
          src={image}
          alt="lisa cummings lead through strengths"
          loading="lazy"
          sizes="(max-width: 300px) 100vw, 300px"
        />
      </Link>
      <header className={classes.postHeader}>
        <Typography variant="subtitle1">
          {authors[authorSlug].name.toUpperCase()}
          &nbsp;/&nbsp;
          <time>{date}</time>
        </Typography>
        <Typography variant="h5" gutterBottom>
          <Link
            to={`/blog/post/${slug}`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/post/${slug}`}
            aria-hidden="true"
            tabIndex="-1"
          >
            {title}
          </Link>
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          {desc}
        </Typography>
      </div>
    </article>
  );
};

export default BlogPostListItem;
