import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  jobRoot: {
    position: 'relative',
    background: 'transparent',
    width: '100%',
    height: '100%',
  },
  companyLogoHolder: {
    marginTop: theme.spacing(1),
    height: 100,
    width: 100,
    borderRadius: '50%',
    margin: 'auto',
    alignItems: 'center',
    overflow: 'hidden',
  },
  companyLogo: {
    height: 100,
    width: 100,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  buttonHolder: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  icon: {
    top: 5,
    position: 'relative',
  },
  jobDetails: {
    padding: theme.spacing(1),
  },
  applyButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Job = ({
  id,
  title,
  company,
  loc,
  logoUrl,
  jobUrl,
  dataGaType,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.jobRoot}`} key={`job${id}`}>
      <div className={classes.companyLogoHolder}>
        <div
          className={classes.companyLogo}
          title={`${company} Company Logo`}
          style={{ backgroundImage: `url(${logoUrl})` }}
        />
      </div>
      <div className={classes.jobDetails}>
        <Typography variant="h6">{title}</Typography>
        <div>
          <Typography variant="body1">
            <LocationCityIcon className={classes.icon} /> {company}
          </Typography>
          <Typography variant="body1">
            <LocationOnIcon className={classes.icon} /> {loc}
          </Typography>
        </div>
      </div>
      <div className={classes.buttonHolder}>
        <Button
          className={classes.applyButton}
          component={Link}
          href={jobUrl}
          target="_blank"
          variant="outlined"
          size="large"
          aria-label="go to job"
          data-ga-type={dataGaType}
          data-ga-content="Apply Now"
        >
          Apply Now
        </Button>
      </div>
    </div>
  );
};

Job.propTypes = {
  loc: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  jobUrl: PropTypes.string.isRequired,
};

export default Job;
