import Button from '@material-ui/core/Button';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { buildResultsUrl } from '../../utils/buildUrl';
import PositionContext from '../common/PositionContext';

const AllJobsButton = ({ step }) => {
  const context = useContext(PositionContext);
  const { position, zipCode } = context.state;

  return (
    <Button
      component={Link}
      to={buildResultsUrl(position, zipCode, 0, { bypass: 1 })}
      data-ga-type="Position_3_Skip_Submit"
      data-ga-content={`step_${step}`}
      fullWidth
      size="small"
    >
      Show Me All Jobs
    </Button>
  );
};

export default AllJobsButton;
