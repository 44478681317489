import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { siteName } from '../../assets/themes';
import environment from '../../configs/environment';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Disclosure = () => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.paper}>
      By clicking the button above, I agree to the {siteName}
      <a href={environment.talroo_terms_page} target="_blank"
         rel="noopener noreferrer"> Terms & Conditions </a>
      &
      <a href={environment.talroo_privacy_page} target="_blank"
         rel="noopener noreferrer"> Privacy Policy </a>
      to receive SMS, text alerts, job alerts and opportunities from
      Employers and {siteName}.com.<br />
      <a
        href={environment.talroo_ccpa_dns_page}
        target="_blank"
        rel="noopener noreferrer"
      >
        Do Not Sell My Personal Information
      </a>.
    </Typography>
  );
};

export default Disclosure;
