import TodayIcon from '@material-ui/icons/Today';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import PositionContext from '../common/PositionContext';
import PositionPickerPage from '../page_layouts/PositionPickerPage';
import AllJobsButton from './AllJobsButton';
import Profile3Form from './Profile3Form';

const steps = [
  {
    dataGaType: 'Profile_3_Step_0',
    icon: <TodayIcon style={{ fontSize: 80 }} />,
    title: 'How Quickly Are You Looking To Start?',
    options: [
      'Right Now',
      'Couple of Weeks',
      'Next Month',
      'Couple of Months',
    ],
  },
  {
    dataGaType: 'Profile_3_Step_1',
    icon: <TodayIcon style={{ fontSize: 80 }} />,
    title: 'What Type of Employment are You Looking For?',
    options: [
      'Seasonal',
      'Part Time',
      'Full Time',
      'Flexible / Gig',
    ],
  },
];

const Profile3Page = () => {
  const context = useContext(PositionContext);
  const { googleLocation } = context.state;
  const [formStep, setFormStep] = useState(0);

  useEffect(
    () => {
      Cookies.set('zip', googleLocation);
      context.updateValue('zipCode', googleLocation);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleLocation],
  );

  let component = null;

  if (formStep === 0 || formStep === 1) {
    const formData = steps[formStep];
    formData['submitCallback'] = () => setFormStep(formStep + 1);
    component = <Profile3Form {...formData}>
      <AllJobsButton step={formStep} />
    </Profile3Form>;
  } else if (formStep === 2) {
    component = <PositionPickerPage>
      <AllJobsButton step={formStep} />
    </PositionPickerPage>;
  } else {
    // TODO: Render something
  }
  return component;
};

export default Profile3Page;
