import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post7 = () => {
  const classes = useStyles();
  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/interview-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/interview-tips`}
        >
          Interview Tips
        </Link>
      </Typography>
    </div>
    <article aria-label="7 Tips To Ace A Virtual Interview">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 465 }}
          src="https://advice.j2c.com/wp-content/uploads/2020/05/J2C_JobInterview-1.jpg"
          alt="man on laptop virtual interview"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/alex`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/alex`}
            rel="author"
          >
            Alexandra Mankar
          </Link>
          &nbsp;/&nbsp;
          <time>May 5, 2020</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          7 Tips To Ace A Virtual Interview
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          There's no question that interviewing is
          the most intimidating part of the process (I mean,&nbsp;<a
          rel="noreferrer noopener"
          href="https://advice.j2c.com/interview-tips/" target="_blank">we have
          a whole section on our advice site dedicated to interviewing tips</a>).
          But what if you could do it from the comfort of&nbsp;your humble
          abode?&nbsp;COVID-19&nbsp;has thrust&nbsp;employers&nbsp;into
          hosting&nbsp;virtual job fairs and virtual interviews as they continue
          to recruit and&nbsp;maintain a safe distance while finding new
          talent.&nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          While virtual interviewing does not allow
          for <em>some</em> tried-and-true methods for making a
          good&nbsp;first&nbsp;impression, there are many ways
          to&nbsp;still&nbsp;wow your potential boss from behind a screen.
          Simply
          follow this guide to prep and perfect your video interview skills…
          because this could be the future of interviewing. &nbsp;
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Preparation is Key
        </Typography>
        <Typography variant="body1" gutterBottom>
          Be sure to do your research on the
          employer ahead of time! Hop onto&nbsp;LinkedIn and check out their
          profile, read reviews&nbsp;on&nbsp;Glassdoor&nbsp;from peers who have
          worked or interned there, and scope out recent news coverage of the
          organization for developments&nbsp;(this can usually be found on their
          “about us”&nbsp;page&nbsp;or&nbsp;through&nbsp;a quick Google
          search).&nbsp;The better equipped you are to ask thoughtful questions,
          the smoother your conversation will go throughout the
          interview.&nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          Use&nbsp;this time to review your resume and
          the job description.&nbsp;Write down&nbsp;how
          your&nbsp;experience&nbsp;might help you exceed in the role.
          Having&nbsp;concrete examples and&nbsp;this information fresh in your
          mind will help you feel more confident during the video
          call.&nbsp;
        </Typography>
        <figure className="wp-block-image size-large">
          <img
            className={classes.img}
            style={{ maxWidth: 2560, maxHeight: 1707 }}
            src="https://advice.j2c.com/wp-content/uploads/2020/05/shutterstock_1606121245-scaled.jpg"
            alt="woman video interviewing"
          />
        </figure>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Dress for success…even remotely
        </Typography>
        <Typography variant="body1" gutterBottom>
          Make sure that you look
          polished and professional — at least up top. (The beauty of video
          calls is that nobody will know if you wear sweatpants&nbsp;with your
          blazer) Consider a button-up shirt or blouse, and be sure to groom
          just as thoughtfully as you would for an in-person meeting. Not only
          will this help you make a great impression with your&nbsp;interviewer
          but&nbsp;wearing clothes that make you feel confident will also help
          you get your head in the game!&nbsp;<a
          href="https://advice.j2c.com/what-to-wear-job-interview/"
          target="_blank" rel="noreferrer noopener">Check out this infographic
          for interview attire tips.</a>
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Think about your background
        </Typography>
        <Typography variant="body1" gutterBottom>
          You probably&nbsp;would not&nbsp;invite your
          future boss over to your house if it were strewn with socks,
          right?&nbsp;So,&nbsp;don't&nbsp;let them see that on video! Find a
          clean, quiet area of your living space&nbsp;– preferably well-lit and
          neutral, like a wall or panel of curtains&nbsp;where you can take the
          call.&nbsp;Get your camera set to a flattering view.&nbsp;Be sure to
          speak with any housemates in&nbsp;advance&nbsp;and&nbsp;stow away your
          pets&nbsp;to ensure that nobody will walk behind you or make loud
          noises while&nbsp;you are&nbsp;in the middle of discussing your
          professional strengths and weaknesses.    &nbsp;
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Show up Early
        </Typography>
        <Typography variant="body1" gutterBottom>
          Yes… this still applies AND is&nbsp;one of
          the easiest ways to start things off on the right foot. You do not
          need to log on 15-minutes early like you would when arriving for
          a&nbsp;traditional&nbsp;in-office interview, plan to&nbsp;enter the
          video chat&nbsp;about three to five minutes early. To ensure that
          everything goes smoothly, make sure that you have the correct software
          loaded (if necessary), and consider a test call&nbsp;a few hours
          before&nbsp;with a trusted friend to work out any kinks in your
          internet connection&nbsp;or with your audio.  &nbsp;Most interviews
          will take place via tools like <a rel="noreferrer noopener"
                                            href="https://zoom.us/"
                                            target="_blank">Zoom</a>, <a
          rel="noreferrer noopener" href="https://www.skype.com/en/"
          target="_blank">Skype</a>, <a rel="noreferrer noopener"
                                        href="https://www.microsoft.com/en-us/microsoft-365/microsoft-teams/group-chat-software?&amp;ef_id=Cj0KCQjwncT1BRDhARIsAOQF9Lm6Vf28KhVdLwtzyMtQZ0f5M5D9zI8brZfLuyWWOFf9yB01e3Vs6IoaAgmeEALw_wcB:G:s&amp;OCID=AID2000955_SEM_Cj0KCQjwncT1BRDhARIsAOQF9Lm6Vf28KhVdLwtzyMtQZ0f5M5D9zI8brZfLuyWWOFf9yB01e3Vs6IoaAgmeEALw_wcB:G:s&amp;gclid=Cj0KCQjwncT1BRDhARIsAOQF9Lm6Vf28KhVdLwtzyMtQZ0f5M5D9zI8brZfLuyWWOFf9yB01e3Vs6IoaAgmeEALw_wcB"
                                        target="_blank">Microsoft Teams</a>,
          but there are many videoconference providers, so be prepared no matter
          which one you'll be using.
        </Typography>
        <figure className="wp-block-image size-large">
          <img
            className={classes.img}
            style={{ maxWidth: 2560, maxHeight: 1440 }}
            src="https://advice.j2c.com/wp-content/uploads/2020/05/shutterstock_1681616224-scaled.jpg"
            alt="woman on laptop video imterviewing"
          />
        </figure>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Make “eye contact” with the camera
        </Typography>
        <Typography variant="body1" gutterBottom>
          If it helps,
          consider putting some googly eyes next to your webcam to remind you to
          look directly at it throughout the call. This gives the appearance of
          making eye contact, not distractedly staring at your screen! Making
          eye contact, even if through a laptop, helps foster a sense of genuine
          connection and attentiveness and can make all the difference when
          trying to hit it off with your interviewer. &nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          **TIP: Make
          sure you let your interviewer know&nbsp;beforehand&nbsp;that you have
          notes and if you look down or away, it's because you are reading
          and/or taking notes – this prevents the assumption that you are not
          connecting with the camera**&nbsp;
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          Stay focused and visibly engaged
        </Typography>
        <Typography variant="body1" gutterBottom>
          One way to show your interviewer that&nbsp;you
          are&nbsp;motivated and engaged is by practicing active
          listening.&nbsp;Limit your distractions and consider nodding your head
          to give affirmations of understanding while they&nbsp;talk
          and&nbsp;ask&nbsp;clarifying questions when necessary.&nbsp;&nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          **TIP: Take hand-written notes! Keep a notebook nearby so that you
          can jot down questions or key takeaways from the interview without
          having to disrupt the flow of the conversation with
          loud&nbsp;typing.&nbsp;**&nbsp;
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          That is not all folks!
        </Typography>
        <Typography variant="body1" gutterBottom>
          After you end your call, be sure not to “ghost”
          your interviewer.&nbsp;A virtual interview is still an interview. Send
          a well thought out professional “thank you” note to your
          interviewers.&nbsp;Check your email at least twice per day to stay
          abreast of any outreach or next steps that might land in your
          inbox!&nbsp;<a rel="noreferrer noopener"
                         href="https://advice.j2c.com/rock-your-follow-up/"
                         target="_blank">Check out 5 post-interview follow-up
          ideas.</a>
        </Typography>
        <Typography variant="body1" gutterBottom>
          With these tips in
          your&nbsp;sweatpants&nbsp;pocket,&nbsp;you will&nbsp;have all the keys
          to succeed in your virtual interview. Now all&nbsp;that is left is
          to&nbsp;find the right opportunity!&nbsp;&nbsp;
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/interview-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/interview-tips`}
            rel="category tag"
          >
            Interview Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post7;
