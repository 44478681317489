const categories = {
  'interview-tips': {
    title: 'Interview Tips',
    posts: [
      '7-tips-to-ace-a-virtual-interview',
    ],
  },
  'salary-negotiation-tips': {
    title: 'Salary Negotiation Tips',
    posts: [
      '5-ways-to-show-your-worth-and-keep-your-job',
    ],
  },
  'resume-tips': {
    title: 'Resume Tips',
    posts: [
      'resume-sections-guide',
      'master-interview-job-search',
      '7-things-never-include-resume',
    ],
  },
  'remote-work-tips': {
    title: 'Remote Work Tips',
    posts: [
      'boost-productivity-working-home',
      'show-remote-work-experience-on-a-resume',
    ],
  },
  'career-advice': {
    title: 'Career Advice',
    posts: [
      '5-important-career-lessons-smart-people-tend-forget',
    ],
  },
  'workplace-issues': {
    title: 'Workplace Issues',
    posts: [
      'can-create-green-workspace',
    ],
  },
  'quitting-jobs': {
    title: 'Quitting Jobs',
    posts: [
      'sure-quit-four-reasons-stay',
    ],
  },
};

export default categories;
