import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post1 = () => {
  const classes = useStyles();

  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/quitting-jobs`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/quitting-jobs`}
        >
          Quitting Jobs
        </Link>
      </Typography>
    </div>
    <article
      aria-label="Are You Sure You Should Quit? Four Reasons to Stay"
    >
      <header>
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 466 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/07/ShouldYouQuit.jpg"
          alt=""
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/charles-ebert`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/charles-ebert`}
            rel="author"
          >
            Charles Ebert
          </Link>
          &nbsp;/&nbsp;
          <time>January 15, 2018</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          Are You Sure You Should Quit? Four Reasons to Stay
        </Typography>
      </header>
      <div>
        <Typography variant="body1" gutterBottom>
          You might be stuck in a monotonous and boring job. You may also have
          great opportunities and prospects out there. You might be wondering
          why you’re holding on to a job that you don’t like. At times, your job
          is not conducive and nurturing. You may not be well remunerated. Your
          boss may be a narcissist. You may be overworked or underworked.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Whatever the case, at times we may feel justified and resolute in our
          decision to quit our jobs. You may be contemplating using a resume
          writing service to polish up your resume for job prospects. However,
          quitting your job has many implications which may leave you regretting
          why you quit in the first place. There are monetary consequences, and
          you’re not guaranteed to get another job right away. The timing may
          also not be right for you to quit your job. Consider these four
          reasons as to why you should not quit your job just yet.
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>You Cannot Afford It</strong>
        </Typography>
        <figure
          id="attachment_11960"
          aria-describedby="caption-attachment-11960"
          style={{ width: 390 }}
        >
          <img
            className={classes.img}
            style={{ maxWidth: 390, maxHeight: 260 }}
            src="https://advice.j2c.com/wp-content/uploads/2017/07/cashbox.jpg"
            alt="Do you have enough to sustain yourself?"
          />
          <figcaption id="caption-attachment-11960">
            <Typography variant="subtitle2" gutterBottom>
              Do you have enough to sustain yourself?
            </Typography>
          </figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Many people are influenced to quit their jobs by exaggerated moves
          gestures from either movies or stories. Well, what people don’t
          realize is that these are only acts and are not a depiction of real
          life. The fashionable way that people quit their jobs in Hollywood
          movies and the false sense of freedom that engulfs them at that moment
          misguides people to quit their jobs in real life.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Many aspects are at stake when you quit your job in the same fashion.
          There are many factors that are stake especially the financial
          aspects. Do you think that you are in a good financial position to
          sustain yourself without a steady source of income? Have you gathered
          enough savings and an emergency fund that will serve as a means of
          survival? Well, for most people the answer is always on the negative!
          This should serve as a reason for you not to quit just yet until you
          can guarantee your financial ability.
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>You're Quitting for the Wrong Reasons</strong>
        </Typography>
        <figure
          id="attachment_11959"
          aria-describedby="caption-attachment-11959"
          style={{ width: 525 }}
        >
          <img
            className={classes.img}
            style={{ maxWidth: 525, maxHeight: 197 }}
            src="https://advice.j2c.com/wp-content/uploads/2017/07/wrong.jpg"
            alt="Are you just wrong about everything?"
          />
          <figcaption id="caption-attachment-11959">
            <Typography variant="subtitle2" gutterBottom>
              Are you just wrong about everything?
            </Typography>
          </figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Well, life, in general, is not guaranteed to be smooth. We all face
          challenges and occurrences that may discourage us in a personal sense.
          Maybe you just need some motivation and some inspiration to get back
          on your feet. The problem with most people is that they quit so easy
          at the face of challenges. They may be blind to the fact that these
          challenges are meant to shape them as individuals.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you’re just demotivated, sad and have no morale, using these as
          reasons to quit is just misguided. You are just quitting for all the
          wrong reasons. You’re better off finding the cause of the
          demotivation, lack of morale and sadness and work with an aim to
          overcome it.
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>Maybe you Just Need a Break</strong>
        </Typography>
        <figure
          id="attachment_11958"
          aria-describedby="caption-attachment-11958"
          style={{ width: 408 }}
        >
          <img
            className={classes.img}
            style={{ maxWidth: 408, maxHeight: 306 }}
            src="https://advice.j2c.com/wp-content/uploads/2017/07/break.jpeg"
            alt="A break may be the only solution."
          />
          <figcaption id="caption-attachment-11958">
            <Typography variant="subtitle2" gutterBottom>
              A break may be the only solution.
            </Typography>
          </figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Do you have perfectionist tendencies? Are you always working till late
          in the night? Have you turned down time offs and opportunities for
          vacations? Are you feeling some work burnout? Do you have less or no
          time for your social life? Well, these should not be a basis for
          quitting your job.
        </Typography>
        <Typography variant="body1" gutterBottom>
          All you need is a break, and you’ll be good to go. In fact, most
          people who were determined to quit but went for a break first had a
          change of heart. So should you. You just need a quick fix. This may
          include some time away from the office environment. You may consider
          going for that vacation you've always wanted but never had the chance
          to go to. You may take some time off or go on leave as you contemplate
          your next move.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Taking some time off gives you the time and space to decide what your
          career path should be and most of the time, people put away all
          thoughts of quitting.
        </Typography>
        <Typography variant="h5" gutterBottom>
          <strong>You are Probably Angry</strong>
        </Typography>
        <figure
          id="attachment_11957"
          aria-describedby="caption-attachment-11957"
          style={{ width: 399 }}
        >
          <img
            className={classes.img}
            style={{ maxWidth: 399, maxHeight: 266 }}
            src="https://advice.j2c.com/wp-content/uploads/2017/07/angry.jpeg"
            alt="Anger may be the underlying cause."
          />
          <figcaption id="caption-attachment-11957">
            <Typography variant="subtitle2" gutterBottom>
              Anger may be the underlying cause.
            </Typography>
          </figcaption>
        </figure>
        <Typography variant="body1" gutterBottom>
          Most people make&nbsp;
          <a
            href="https://www.themuse.com/advice/4-surprising-signs-you-should-fix-your-current-job-instead-of-quitting"
            /* eslint-disable-next-line react/jsx-no-target-blank */
            target="_blank"
            rel="nofollow noopener"
          >
            decisions about quitting their jobs
          </a>
          &nbsp;impulsively. The impulse that’s frequently acted upon is anger.
          Maybe your boss has been on your neck, or probably you had a hectic
          week at work. As always, our minds may just look for the next easy
          solution and that’s quitting. However, this may not be the best
          solution. In fact, your decision is purely emotional and based on
          impulse. You would be better off excusing yourself and probably go
          home and take things back into perspective.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Give yourself some ample time to calm down and relax so that you can
          brainstorm on a viable solution. If it’s about personal differences
          with your boss or colleagues, be proactive in finding a balance
          between your personal and professional life. If it is about some of
          the work processes that are becoming overly hectic hence making you
          angry, raise the issue with the concerned party in the company so that
          a viable solution can be sought.
        </Typography>
        <Typography variant="body1" gutterBottom>
          With current trends showing that&nbsp;
          <a
            href="https://www.usnews.com/news/articles/2016-10-12/americans-quitting-their-jobs-en-masse-says-bureau-of-labor-statistics"
            /* eslint-disable-next-line react/jsx-no-target-blank */
            target="_blank"
            rel="nofollow noopener"
          >
            more people are quitting their jobs
          </a>
          &nbsp;in recent times than before, this is not a decision to just make
          lightly. At times, quitting is not the best solution. Do an honest
          soul search against these four reasons and see why you should not quit
          your job just yet. Don’t quit unless you’re absolutely sure this is
          the best course of action for you!
        </Typography>
        <p>
          <i>
            <span style={{ fontWeight: 400 }}>
              Source for in-post images:&nbsp;
            </span>
          </i>
          <a href="https://www.pexels.com">
            <i>
              <span style={{ fontWeight: 400 }}>Pexels</span>
            </i>
          </a>
          <i>
            <span style={{ fontWeight: 400 }}> and </span>
          </i>
          <a href="https://pixabay.com">
            <i>
              <span style={{ fontWeight: 400 }}>Pixabay</span>
            </i>
          </a>
          <i>
            <span style={{ fontWeight: 400 }}>.</span>
          </i>
        </p>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/quitting-jobs`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/quitting-jobs`}
            rel="category tag"
          >
            Quitting Jobs
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post1;
