import queryString from 'query-string';

const buildUrl = (path, q, l, start, extra) => {
  const currentParams = queryString.parse(window.location.search);
  const params = {
    q,
    l,
    start,
  };

  return `${path}?${queryString.stringify(
    Object.assign(currentParams, params, extra),
    {
      skipNull: true,
    },
  )}`;
};

export const buildResultsUrl = (
  q,
  l,
  start = null,
  extra = {},
) => {
  let path = '/results';
  if (window.location.pathname === '/profile2') {
    path = '/profile2';
  } else if (window.location.pathname === '/') {
    path = '/';
  }
  return buildUrl(path, q, l, start, extra);
};

export const buildProfileUrl = () => buildUrl('/profile');
