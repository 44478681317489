import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import * as PropTypes from 'prop-types';
import React, { useContext } from 'react';
import PositionContext from './common/PositionContext';

const useStyles = makeStyles((theme) => ({
  textField: {
    border: 0,
    backgroundColor: theme.palette.common.white,
  },
  cssOutlinedInput: (props) => ({
    height: 40,
    borderRadius: 0,
  }),
  itemHolder: {
    padding: theme.spacing(1),
  },
}));

const MobileSearchForm = ({ keyword, loc, onClick }) => {
  const context = useContext(PositionContext);
  const { zipCode } = context.state;

  if (!loc && zipCode) {
    loc = zipCode;
  }

  const classes = useStyles();

  const handleClick = () => {
    onClick();
  };

  const value = keyword ? `${keyword} in ${loc}` : `Jobs in ${loc}`;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.itemHolder}>
        {/*<label htmlFor="position-text-field">Position</label>*/}
        <TextField
          id="mobile-search-field"
          name="mobile-search-field"
          fullWidth
          size="small"
          className={classes.textField}
          onClick={handleClick}
          InputProps={{
            startAdornment: <InputAdornment
              position="start"><SearchIcon /></InputAdornment>,
            classes: {
              root: classes.cssOutlinedInput,
            },
          }}
          variant="outlined"
          value={value}
        />
      </Grid>
    </Grid>
  );
};

MobileSearchForm.propTypes = {
  keyword: PropTypes.string,
  loc: PropTypes.string,
};

MobileSearchForm.defaultProps = {
  keyword: '',
  loc: '',
};

export default MobileSearchForm;
