import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import * as PropTypes from 'prop-types';
import React from 'react';

import themes from '../assets/themes';
import withQueryParameters from '../hocs/withQueryParameters';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  textHolderHeader: {
    '@media (max-width: 359px)': {
      fontSize: '1.40rem',
    },
    fontSize: '1.85rem',
    fontWeight: 'bold',
  },
  copy2Text: {
    '@media (max-width: 359px)': {
      fontSize: '1rem',
    },
    fontSize: '1.25rem',
  },
  textHolder: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
  icon: {
    paddingRight: theme.spacing(1),
    top: 5,
    position: 'relative',
  },
  companyImageHolder: {
    width: '100%',
    '@media (max-width: 359px)': {
      height: 200,
      marginBottom: theme.spacing(2),
    },
    height: 225,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  arrow: {
    color: theme.palette.primary.main,
    fontSize: 50,
  },
}));

const getCompanyImage = (companyName, imageVersion) => {
  let image = themes.main.header_image[1];

  if (companyName in themes) {
    if (imageVersion in themes[companyName].header_image) {
      image = themes[companyName].header_image[imageVersion];
    } else {
      image = themes[companyName].header_image[1];
    }
  } else if (imageVersion in themes.main.header_image) {
    image = themes.main.header_image[imageVersion];
  }

  return image;
};

const Header = ({ company, copy1, copy2, image }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles({ matches });

  return (
    <div className={classes.root}>
      <div className={classes.textHolder}>
        <Typography variant="h1" className={classes.textHolderHeader}>
          {copy1}
        </Typography>
      </div>
      <div
        className={classes.companyImageHolder}
        style={{
          backgroundImage: `url(${getCompanyImage(company, image)})`,
        }}
      >
      </div>
      <div className={classes.textHolder}>
        <Typography variant="h6" gutterBottom className={classes.copy2Text}>
          <AccessTimeIcon className={classes.icon} />
          {copy2}
        </Typography>
      </div>
    </div>
  );
};

Header.propTypes = {
  company: PropTypes.string,
  copy1: PropTypes.string,
  copy2: PropTypes.string,
  image: PropTypes.number,
};

Header.defaultProps = {
  company: '',
  copy1: 'Featured Jobs Hiring Now',
  copy2: 'Full & Part Time Opportunities',
  image: 1,
};

export default withQueryParameters(Header);
