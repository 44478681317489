import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import BlogAuthorPage from './components/page_layouts/BlogAuthorPage';
import BlogCategoryPage from './components/page_layouts/BlogCategoryPage';
import BlogPage from './components/page_layouts/BlogPage';
import NotFoundPage from './components/page_layouts/NotFoundPage';
import PostsPage from './components/page_layouts/PostsPage';
import UnsubscribePage from './components/page_layouts/UnsubscribePage';
import HomePageRoute from './components/routes/HomePageRoute';
import Profile2Route from './components/routes/Profile2Route';
import Profile3Route from './components/routes/Profile3Route';
import ProfileRoute from './components/routes/ProfileRoute';
import ResultsRoute from './components/routes/ResultsRoute';

const Nav = () => (
  <Switch>
    <HomePageRoute path="/" exact />
    <ProfileRoute path="/profile" exact />
    <Profile3Route path="/profile3" exact />
    <Profile2Route path="/profile2" exact />
    <ResultsRoute path="/results" exact />
    <Route path="/blog" component={PostsPage} exact />
    <Route path="/blog/author/:author" component={BlogAuthorPage} exact />
    <Route path="/blog/category/:category" component={BlogCategoryPage} exact />
    <Route path="/blog/post/:post" component={BlogPage} exact />
    <Route path="/unsubscribe" component={UnsubscribePage} exact />
    <Route path='/404' component={NotFoundPage} />
    <Redirect from='*' to='/404' exact />
  </Switch>
);

export default Nav;
