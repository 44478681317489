import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sectionHeader: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
}));

const Post3 = () => {
  const classes = useStyles();
  return <main id="genesis-content">
    <div>
      <Typography variant="subtitle1" gutterBottom>
        <Link
          to={`/blog`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog`}
        >
          Home
        </Link>
        &nbsp;
        <span aria-label="breadcrumb separator">/</span>
        &nbsp;
        <Link
          to={`/blog/category/remote-work-tips`}
          data-ga-type="Blog_Click"
          data-ga-content={`/blog/category/remote-work-tips`}
        >
          Remote Work Tips
        </Link>
      </Typography>
    </div>
    <article aria-label="How to Show Remote Work Experience on a Resume">
      <header className="entry-header">
        <img
          className={classes.img}
          style={{ maxWidth: 848, maxHeight: 466 }}
          src="https://advice.j2c.com/wp-content/uploads/2017/01/workremote.png"
          alt="remote work"
        />
        <Typography variant="subtitle1" gutterBottom>
          <Link
            to={`/blog/author/felixt`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/author/felixt`}
            rel="author"
          >
            Felix Tarcomnicu
          </Link>
          &nbsp;/&nbsp;
          <time>January 25, 2018</time>
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.pageTitle}>
          How to Show Remote Work Experience on a Resume
        </Typography>
      </header>
      <div className="entry-content">
        <Typography variant="body1" gutterBottom>
          The first question you might ask is “Why should I show remote work
          experience on my resume?” First off, let’s define what remote work
          means. It’s paid undertakings; jobs whether as projects or contracted
          work that are conducted online from a remote location.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Remote workers are alternatively referred to as virtual assistants,
          freelancers, home-based workers or the more accepted,
          politically-correct term telecommuters. &nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          There are four good reasons why you should reflect work in your
          resume:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>
            It informs the recruiter that you are interested in landing a remote
            worker position in the company.
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>
            It shows leadership spirit; you are willing to be accountable for
            your career and financial independence.
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>
            It readily highlights your strengths; a potential employer will take
            note that hiring you would entail reduced training expenses.
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>
            If you have difficulty finding regular employment, remote worker can
            magnificently fill in the gap which recruiters are likely to
            question.
          </Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          There are also two valid reasons why you should reconsider putting
          your remote work experience in your resume:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>
            The potential employer may think you are still doing remote work and
            may not commit to the demands of the job.
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>
            It may present you as someone who’d rather be in charge than take
            instruction.
          </Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          All things considered, it is better to show your remote work
          experience because the resume is your first point of contact with a
          recruiter. You should lay down all your cards and take the risk. At
          this point of the job search, the resume is the only document that can
          get you the all- important interview. By all means, go for it!
        </Typography>
        <Typography variant="body1" gutterBottom>
          But if you want to avoid the risk of having your remote work
          experience go against your chances of landing the job, here is the
          best way to go about it:
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          1. Establish Your Purpose for Including Your Remote Work Experience.
        </Typography>
        <Typography variant="body1" gutterBottom>
          As discussed earlier, there are three reasons why you should include
          your remote work experience in the resume. The answer to “Why” is your
          purpose and it will determine where you should place remote work
          experience in the resume.
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>
            <b>Summary –</b> If your intent is to land a remote worker position
            in the company, have your experience included in the summary. This
            way the recruiter can immediately see it and tie it in with the rest
            of your resume.
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>
            <b>Skills Section –</b> If you want to highlight your strengths,
            place your remote work experience in the skills section.
          </Typography></li>
          <li><Typography variant="body1" gutterBottom>
            <b>Work Experience –</b> Placing it here will show the recruiter you
            value your experience as a remote worker. You take pride in taking
            chances for yourself and are willing to take accountability for your
            own decisions.
          </Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          If you’ve had a difficult time looking for work after being laid off
          or a brief resignation, placing your remote work experience in this
          section will fill in the gap nicely.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Recruiters are aware employment can be cyclical and difficult at
          times. They would prefer that you remain productive during these down
          periods.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          2. Determine the Relevance of Your Remote Work to the Job Position.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If your remote work experience is relevant to the requirements of the
          job you are applying for, you should highlight them.
        </Typography>
        <Typography variant="body1" gutterBottom>
          For example, you are applying for the position of Project Manager in a
          Digital Marketing company. If you had previous experience managing
          remote teams in digital marketing, you should capitalize on this. It
          should be frequently mentioned in different parts of the resume.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can include it as part of your Summary:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <i>
            “Designated Project Manager for a remote digital marketing team that
            handled 3 campaigns from different industries. My remote team
            included an SEO professional, a social media marketer, web developer
            and content writer. As Project Manager, my primary responsibility
            was to design the digital marketing campaign and closely collaborate
            with my team to stay on track of its performance.”
          </i>
        </Typography>
        <Typography variant="body1" gutterBottom>
          You can include your remote work experience in the Work Experience
          section where you should go into complete detail. Include the
          following information:
        </Typography>
        <ul>
          <li><Typography variant="body1" gutterBottom>Name of
            Client</Typography></li>
          <li><Typography variant="body1" gutterBottom>Main
            Objective</Typography></li>
          <li><Typography variant="body1" gutterBottom>Courses of
            Action</Typography></li>
          <li><Typography variant="body1" gutterBottom>Results; indicate actual
            numbers and figures</Typography></li>
        </ul>
        <Typography variant="body1" gutterBottom>
          On the other hand, if the remote work experience is not relevant, you
          can include it in your overall work history but you do not have to go
          into detail. If you do, it may detract the recruiter from the more
          critical bits of information.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          3. It Must be Related to Your Skills Or….
        </Typography>
        <Typography variant="body1" gutterBottom>
          There are recruiters who place a premium on candidates whose skills
          and work experience show the company will not have to spend time and
          resources on additional training.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Many companies want to generate ROI on their human assets. They
          understand the value of training but they would prefer it if the
          candidate can contribute immediately to the company’s coffers. &nbsp;
        </Typography>
        <Typography variant="body1" gutterBottom>
          For example, if you are applying for the position of Market Researcher
          and they see you’ve had previous remote work experience doing
          extensive research for a variety of clients it may be enough to
          convince the recruiter you deserve the interview.
        </Typography>
        <Typography variant="body1" gutterBottom>
          In this case, your remote work experience worked to highlight your
          strength and core competence in the area of market research.
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.sectionHeader}>
          4. It Must be Perceived to Improve Your Weaknesses.
        </Typography>
        <Typography variant="body1" gutterBottom>
          On the other hand, you could present your remote work experience as
          your way of improving a weakness.
        </Typography>
        <Typography variant="body1" gutterBottom>
          For example, if you are applying for the position of Accounts
          Specialist but your remote work experience was that of a data encoder.
          You can present this in the Summary as follows:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <i>
            “From March to August 2015, I took a remote job as a Data Entry
            Specialist for Deutsche Bank. I was tasked to verify all raw data
            collected from various sources then enter them into specific data
            bases. Not only was this a great opportunity to work with one of the
            most successful banks in the world, but it was the best way to
            improve on my analytical and encoding skills.”
          </i>
        </Typography>
        <Typography variant="body1" gutterBottom>
          No matter how you look at it, remote work still accounts for
          experience. It still represents a responsibility that you had to
          complete and submit according to the demands of the client. Including
          it in your resume will present more advantages than risks.
        </Typography>
      </div>
      <footer className={classes.footer}>
        <Typography varaint="subtitle2">
          Filed Under:&nbsp;
          <Link
            to={`/blog/category/remote-work-tips`}
            data-ga-type="Blog_Click"
            data-ga-content={`/blog/category/remote-work-tips`}
            rel="category tag"
          >
            Remote Work Tips
          </Link>
        </Typography>
      </footer>
    </article>
  </main>;
};

export default Post3;
