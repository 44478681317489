import Cookies from 'js-cookie';
import * as md5 from 'md5';
import * as PropTypes from 'prop-types';
import * as queryString from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
);

// https://stackoverflow.com/questions/42862253/how-to-parse-query-string-in-react-router-v4
const queryParametersToObject = (queryParameters) =>
  queryString.parse(queryParameters);

const processQueryParameters = (search) => {
  const queryParameters = (search !== '')
    ? queryParametersToObject(search.substring(1))
    : {};

  if (queryParameters.uid) {
    Cookies.set('uid', queryParameters.uid);
  } else {
    queryParameters.uid = Cookies.get('uid');
  }

  if (queryParameters.q) {
    Cookies.set('position', queryParameters.q);
  } else {
    queryParameters.q = Cookies.get('position');
  }

  if (queryParameters.l) {
    Cookies.set('zip', queryParameters.l);
  } else {
    queryParameters.l = Cookies.get('zip');
  }

  if (queryParameters.email) {
    Cookies.set('uid', md5(queryParameters.email));
  }

  // Used to grab the c0-9 values and store them as cookie for use later.
  for (let i = 0; i <= 9; i++) {
    const cookieName = `c${i}`;
    if (cookieName in queryParameters) {
      Cookies.set(cookieName, queryParameters[cookieName]);
    }
  }

  return queryParameters;
};

const withQueryParameters = (WrappedComponent) => {
  class WithQueryParameters extends Component {
    constructor(props) {
      super(props);

      const { location } = props;
      const { search } = location;
      const queryParameters = processQueryParameters(search);

      this.state = { ...queryParameters, search };
    }

    static getDerivedStateFromProps(props, state) {
      if (props.location.search !== state.search) {
        return {
          ...processQueryParameters(props.location.search),
          search: props.location.search,
        };
      }

      return null;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props} // eslint-disable-line react/jsx-props-no-spreading
          {...this.state} // eslint-disable-line react/jsx-props-no-spreading
        />
      );
    }
  }

  WithQueryParameters.displayName = `WithQueryParameters(${getDisplayName(
    WrappedComponent,
  )})`;

  WithQueryParameters.propTypes = {
    location: PropTypes.object.isRequired,
  };

  return withRouter(WithQueryParameters);
};

export default withQueryParameters;
