import Post1 from './posts/Post1';
import Post10 from './posts/Post10';
import Post2 from './posts/Post2';
import Post3 from './posts/Post3';
import Post4 from './posts/Post4';
import Post5 from './posts/Post5';
import Post6 from './posts/Post6';
import Post7 from './posts/Post7';
import Post8 from './posts/Post8';
import Post9 from './posts/Post9';

const posts = {
  '7-tips-to-ace-a-virtual-interview': {
    post: Post7,
    title: '7 Tips To Ace A Virtual Interview',
    desc: 'There\'s no question that interviewing is the most intimidating part of the process (I mean, we have a whole section on our advice site dedicated to …',
    image: 'https://advice.j2c.com/wp-content/uploads/2020/05/J2C_JobInterview-1.jpg',
    author: 'alex',
    date: 'MAY 5, 2020',
  },
  '5-ways-to-show-your-worth-and-keep-your-job': {
    post: Post8,
    title: '5 Ways to Show Your Worth and Keep Your Job',
    desc: 'In our current economic climate, unless your company is deemed essential, you might be facing layoffs or furloughs until we get to the other side of the current …',
    image: 'https://advice.j2c.com/wp-content/uploads/2020/04/5-Ways-to-Show-Your-Worth-and-Keep-Your-Job2-768x422.png',
    author: 'jmillermerrell',
    date: 'APRIL 16, 2020',
  },
  'resume-sections-guide': {
    post: Post5,
    title: 'Resume Sections Guide: Which You Need & How To Use Them',
    desc: 'Learning how to write a resume is easier than ever before. The difficult thing is knowing which resume sections you truly need. Although the task itself is …',
    image: 'https://advice.j2c.com/wp-content/uploads/2019/02/resume-sections-guide.jpg',
    author: 'travis',
    date: 'MARCH 18, 2020',
  },
  'master-interview-job-search': {
    post: Post6,
    title: '5 Ways to Master the Interview Before Your Job Search',
    desc: 'More than ever, job seekers must be proficient at interviewing. In fact, it\'s likely every networking opportunity is an essential job conversation. It may be …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/04/interviewtest.png',
    author: 'markdyson',
    date: 'MARCH 18, 2020',
  },
  '7-things-never-include-resume': {
    post: Post4,
    title: '7 Things You Should Never Include on a Resume',
    desc: 'Let’s face it, very few people really enjoy writing a resume. A quick web search on “how to write a resume” brings up tons of articles that often contradict …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/05/7neversresume-768x422.png',
    author: 'nkhaykis',
    date: 'FEBRUARY 22, 2018',
  },
  'boost-productivity-working-home': {
    post: Post2,
    title: '7 Tips to Boost Productivity When Working From Home',
    desc: 'Working from home was once reserved for the writers, artists, and entrepreneurs of the world. Today, it’s much more common, with approximately 3.9 million …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/08/workingfromhome-768x422.jpg',
    author: 'alex',
    date: 'JANUARY 26, 2018',
  },
  '5-important-career-lessons-smart-people-tend-forget': {
    post: Post10,
    title: '5 Important Career Lessons Smart People Tend to Forget',
    desc: 'In today’s society, money is definitely a necessity. They say that money doesn’t bring happiness, yet this very statement is questionable. We want to have fun, …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/07/Career-Lessons-768x422.jpg',
    author: 'evawislow',
    date: 'JANUARY 26, 2018',
  },
  'show-remote-work-experience-on-a-resume': {
    post: Post3,
    title: 'How to Show Remote Work Experience on a Resume',
    desc: 'The first question you might ask is “Why should I show remote work experience on my resume?” First off, let’s define what remote work means. It’s paid …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/01/workremote-768x422.png',
    author: 'felixt',
    date: 'JANUARY 25, 2018',
  },
  'can-create-green-workspace': {
    post: Post9,
    title: 'How You Can Create Your Own Green Workspace',
    desc: 'Let’s face it, a lot of offices don’t exactly have the freshest vibe. You thought that one coworker was toxic? Between the dust bunnies hiding behind your …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/01/greenwork2.jpg',
    author: 'brynhuntpalmer',
    date: 'JANUARY 25, 2018',
  },
  'sure-quit-four-reasons-stay': {
    post: Post1,
    title: 'Are You Sure You Should Quit? Four Reasons to Stay',
    desc: 'You might be stuck in a monotonous and boring job. You may also have great opportunities and prospects out there. You might be wondering why you’re holding on …',
    image: 'https://advice.j2c.com/wp-content/uploads/2017/07/ShouldYouQuit.jpg',
    author: 'charles-ebert',
    date: 'JANUARY 15, 2018',
  },
};

export default posts;
